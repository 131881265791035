export const Default_FAQ_Content = {
  faqHero: {
    faqHeader: "",
    faqHeroImage: "",
    faqText: "",
  },

  faqContent: [
    {
      faqQuestion: "",
      faqAnswer: "",
    },
    {
      faqQuestion: "",
      faqAnswer: "",
    },
    {
      faqQuestion: "",
      faqAnswer: "",
    },
  ],
};
