import { CartShopProvider } from "./Cart-shop-context";
import { GetUserLocationProvider } from "./User-location-context";
import { FormProvider } from "./form-context";
const ContextProvider = ({ children }) => {
  return (
    <CartShopProvider>
      <GetUserLocationProvider>
        <FormProvider>{children}</FormProvider>
      </GetUserLocationProvider>
    </CartShopProvider>
  );
};

export default ContextProvider;
