export const contact = [
  {
    id: 1,
    country: "Lebanon",
    street:
      "Nadim Al Jisr Street, Building-1812 , Office #5 Ground floor Tripoli, North Lebanon",

    email: "Peter@samapremium.co",
    emailLink: "mailto:Peter@samapremium.co",
    number: [" +961 6 931943 ", "+961 70 339088"],
  },
  {
    id: 2,
    country: "Kuwait",
    street:
      "Block 4 Street 25 Building-125 Floor 4 Office #8, Al Ahmadi, Kuwait",

    email: "Johnny@samapremium.co",
    emailLink: "mailto:Jhonny@samapremium.co",
    number: ["+965 666 43448"],
  },
];
