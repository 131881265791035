// UI
import Container from "../../components/container/Container";
import Button from "../../components/UI/Button/Button";
// image
import { comparison, can } from "./Home-images";
// styles
import styles from "../../style";
// css
import "./Comparison.css";

const Comparison = () => {
  return (
    <section className={`${styles.spacingY} pb-2`}>
      <Container>
        <div className=" overflow-hidden">
          <div className="px-1 px-lg-5 overflow-hidden mt-5">
            {/* <!-- table --> */}
            <div className="flex flex-col h-[100%] font-zarid">
              {/* <!-- table --> */}
              <div className="colm-table inline-flex w-full overflow-x-scroll lg:overflow-x-hidden my-5">
                {/* <!-- 1 --> */}
                <div className="colm-one lg:w-[30%] py-6">
                  <span className="box-class first-col">
                    <p className="scroll-text">Scroll to left</p>
                  </span>
                  <span className="box-class first-col box-class-image bg-gardient">
                    <p className="table-header font-siriRegular">Us vs Them</p>
                  </span>
                  <p className="box-class first-col">Price</p>
                  <p className="box-class first-col">Fresh</p>
                  <p className="box-class first-col">
                    Actually from one country
                  </p>
                  <p className="box-class first-col">
                    Promotes a healthy heart
                  </p>
                </div>
                {/* <!-- 2 --> */}
                <div className="colm border-left py-6">
                  <p className="box-class font-siriRegular text-xl">
                    CHEAP OLIVE OIL
                  </p>
                  <span className="box-class box-class-image">
                    <img src={comparison} alt="" className="object-fit" />
                  </span>
                  <p className="box-class">$</p>
                  <p className="box-class">
                    it is actually blended with olive oil
                  </p>
                  <p className="box-class">Combines oil from 4+ countries</p>
                  <p className="box-class">
                    <ion-icon name="close-outline"></ion-icon>
                  </p>
                </div>
                {/* <!-- 3 --> */}
                <div className="colm sama border-style py-6 bg-white ">
                  <p className="box-class font-siriRegular text-3xl">SAMA</p>
                  <span className="box-class box-class-image">
                    <img src={can} alt="" className="" />
                  </span>
                  <p className="box-class">$$</p>
                  <p className="box-class">Always</p>
                  <p className="box-class">Pure Lebanese</p>
                  <p className="box-class">
                    <ion-icon name="checkmark-outline"></ion-icon>
                  </p>
                </div>
                {/* <!-- 4 --> */}
                <div className="colm py-6">
                  <p className="box-class font-siriRegular text-xl">
                    OVERPRICED OLIVE OIL
                  </p>
                  <span className="box-class box-class-image">
                    <img src={comparison} alt="" className="object-fit" />
                  </span>
                  <p className="box-class">$$$$$</p>
                  <p className="box-class">Occasionally..</p>
                  <p className="box-class">
                    <ion-icon name="checkmark-outline"></ion-icon>
                  </p>
                  <p className="box-class">
                    <ion-icon name="checkmark-outline"></ion-icon>
                  </p>
                </div>
                {/* <!-- 5 --> */}
                <div className="colm border-left py-6">
                  <p className="box-class font-siriRegular text-xl">
                    CONOLA/ <br />
                    VEGGIE OIL
                  </p>
                  <span className="box-class box-class-image">
                    <img src={comparison} alt="" className="object-fit" />
                  </span>
                  <p className="box-class">$</p>
                  <p className="box-class">Bleached, refined</p>
                  <p className="box-class">too many countries</p>
                  <p className="box-class">hazardous</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          to={"/shop"}
          data={
            "NO BLENDING. Only 100% Souri Olives from a big Lebanese field."
          }
          className={
            "font-zarid text-2xl py-10 ss:text-[1.7rem] sm:text-3xl lg:py-8 leading-none mt-16  w-[98%]  mx-auto"
          }
        />
      </Container>
    </section>
  );
};

export default Comparison;
