import { useState } from "react";
import { Link } from "react-router-dom";

import { logo } from "../../assests/images/index";

const ProductsCard = (props) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const imageSrc = props.image;
  const videoSrc = props.video;
  const [src, setSrc] = useState(imageSrc);

  return (
    <div
      className="flex-1 flex flex-col cursor-pointer w-full"
      onMouseEnter={() => setSrc(videoSrc)}
      onMouseLeave={() => setSrc(imageSrc)}
    >
      <div className=" w-full relative  flex flex-col">
        <div className="h-[330px] sm:h-[600px]  md:h-[320px]">
          <img
            className={`${
              src === imageSrc
                ? "w-[100%] h-[100%] object-contain rounded-md transition ease-in duration-300 "
                : "hidden"
            } `}
            src={src}
            alt="sama olive oil bottle and short video"
          />
          <video
            className={`video ${
              src === videoSrc
                ? "w-[100%] h-[100%] object-cover rounded-md"
                : "hidden"
            }`}
            src={src}
            autoPlay
            loop
            muted
            playsInline
            poster={logo}
            webkit-playsinline="true"
          />
        </div>
      </div>
      <Link onClick={scrollToTop} to={"/shop"}>
        <div className="mt-4 font-zarid text-green-primary text-center">
          <p className="flex items-center justify-center gap-x-6">
            {props.name.map((name, i) => (
              <span
                key={name}
                className={`font-siriRegular leading-none ${
                  i === 1 ? "text-[2.4rem] " : "text-2xl"
                } `}
              >
                {name}
              </span>
            ))}
          </p>
          <p className="max-w-fit mx-auto relative font-siriRegular text-[2.4rem] leading-none after:w-[100%] after:h-[2px] after:bg-green-primary  after:absolute after:left-0 after:-bottom-[-7px]">
            {props.title}
          </p>
          <p className="text-[2rem] font-zaridLight leading-none word-spacing mt-1">
            {props.description}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default ProductsCard;
