import { Link } from "react-router-dom";
// css
import "./Button.css";

const Button = (props) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const classes = "button text-green-primary " + props.className;
  return (
    <Link onClick={props.onClick} to={props.to}>
      <button
        onClick={scrollToTop}
        className={classes}
        data-content={props.data}
      ></button>
    </Link>
  );
};

export default Button;
