// custom hook
import useGetData from "../../hooks/use-get-data";
// styles
import { productStyle } from "../../style";
// UI
import Container from "../container/Container";
import Button from "../UI/Button/Button";
// components
import ProductsHeader from "./ProdcutsHeader";
import ProductsCard from "./ProductsCard";

// Data
import { products } from "../../constant/Products";
import getProductComponentContent from "../../services/fetchingData/fetchProductComponentContent";

const Products = (props) => {
  const classes = "relative " + props.className;
  // const data = useGetData(getProductComponentContent);
  // const content = data?.ProductComponentData;

  return (
    <section className={classes}>
      <Container>
        <ProductsHeader />

        <div className={productStyle.prodcutsCardContainer}>
          {products.map((product) => (
            <ProductsCard
              key={product.id}
              image={product.image}
              video={product.video}
              name={product.name}
              title={product.title}
              description={product.description}
            />
          ))}
        </div>

        <Button
          to={"/shop"}
          data={"View Collection"}
          className={
            "font-zarid text-3xl py-6 ss:text-4xl  ss:py-8 leading-none mt-16 w-[98%] mx-auto"
          }
        />
      </Container>
    </section>
  );
};

export default Products;
