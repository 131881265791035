import { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
// UI
import Container from "../../components/container/Container";

const Blog = () => {
  const [header, setHeader] = useState("");
  const [text, setText] = useState("");
  const [hidden, setHidden] = useState(false);
  const location = useLocation().pathname;
  // useEffect to change the content of the header in the main page of both eduction and recipes, and remove it when we move to blog page (articale page)
  useEffect(() => {
    if (location === "/blog/recipes") {
      setHeader("recipes");
      setText("All the taste of olive oil comes in the food.");
      setHidden(false);
    }
    if (location === "/blog/eduction") {
      setHeader("education");
      setText("All you need to know about the olive oil.");
      setHidden(false);
    } else if (
      location === "/blog/recipes/recipesBlog" ||
      location === "/blog/eduction/educationBlog"
    ) {
      setHidden(true);
    }
  }, [location]);

  return (
    <>
      <section
        className={`${
          hidden ? "hidden" : "block"
        } bg-background-green text-white`}
      >
        <Container>
          <div className="flex flex-col items-center justify-center py-20">
            <h1 className="font-siriRegular text-5xl capitalize pb-2">
              {header}
            </h1>
            <p className="font-zaridLight text-2xl text-center leading-none ">
              {text}
            </p>
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-y-4 md:gap-y-0 md:gap-x-4 pb-4">
            <span className="w-full md:w-[60%] flex items-center justify-between font-zarid text-xl border border-white py-2 px-5">
              <input
                type="Search"
                placeholder="Search"
                className="bg-transparent w-full focus:outline-none"
              />
              <ion-icon name="search-outline"></ion-icon>
            </span>

            <span className="flex w-full md:w-[40%] gap-x-4">
              <Link
                to={"/blog/recipes"}
                className={
                  "w-full font-siriRegular text-xl tracking-wide flex items-center justify-center px-8 py-2 border border-white hover:bg-white hover:text-green-primary transition ease-in duration-200"
                }
              >
                <p>Recipes</p>
              </Link>
              <Link
                to={"/blog/eduction"}
                className={
                  "w-full font-siriRegular text-xl tracking-wide flex items-center justify-center px-8 py-2 border border-white hover:bg-white hover:text-green-primary transition ease-in duration-200 active:bg-white"
                }
              >
                <p>Education</p>
              </Link>
            </span>
          </div>
        </Container>
      </section>
      {/* Outlet will be either "Recipe" or "eduction" blog page (hero and blogs container) */}
      <Outlet />
    </>
  );
};

export default Blog;
