import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
// Styles
import "./Header.css";
// Components
import Container from "../../components/container/Container";
import Navigation from "./HeaderComponents/Navigation/Navigation";
import Promotion from "./HeaderComponents/Promotion";

const Header = () => {
  const location = useLocation().pathname;
  const [isBlogPage, setIsBlogPage] = useState(false);
  const headerRef = useRef(null);

  const handleScroll = useCallback(() => {
    const offset = window.scrollY;
    const header = headerRef.current;
    if (offset > 0) {
      header.classList.add("sticky");
    }
    if (offset === 0) {
      header.classList.remove("sticky");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    setIsBlogPage(location.includes("/blog"));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, location]);

  return (
    <header
      ref={headerRef}
      className={`header-section  ${
        isBlogPage ? "greenBackground" : "primaryBackground"
      }`}
    >
      <Promotion blog={isBlogPage} />
      <Container>
        <Navigation blog={isBlogPage} />
      </Container>
    </header>
  );
};

export default Header;
