// UI
import Container from "../../components/container/Container";
import InnerContainer from "../../components/container/InnerContainer";
// data
import { theTeam } from "../../constant/About";
// image
import { teamImage } from "./about-images";
// import
import styles from "../../style";

const TheTeam = () => {
  return (
    <section className={styles.spacingY}>
      <Container>
        <h1 className={`${styles.header} lg:w-[85%]`}>{theTeam.mainHeader}</h1>
      </Container>
      <InnerContainer>
        <div className="flex flex-col md:flex-row gap-x-8">
          <div className="w-[full] md:w-[30%]">
            <h4 className="text-3xl font-siriRegular leading-[38px] text-green-primary text-center mb-2 md:mb-0 md:text-start">
              Sincerely, <br />
              The Sama Team
            </h4>
          </div>
          <div className="w-[full] md:w-[70%] h-[600px]">
            <img
              src={teamImage}
              alt="olive tree"
              className="h-[100%] w-[100%] object-cover"
            />
          </div>
        </div>
      </InnerContainer>
    </section>
  );
};

export default TheTeam;
