import React from "react";

const BlogHero = (props) => {
  const data = props.data;
  return (
    <section className="-translate-y-[6.5rem] h:auto  lg:h-[105vh]  ">
      <div className="flex flex-col lg:flex-row h-[100%]">
        <div className="w-full lg:w-[50%] h-[550px] md:h-[600px] lg:h-[105vh] flex flex-col items-center justify-center  ">
          <img
            className="w-[100%] h-[100%] lg:h-[99.99%] object-cover"
            src={data.image}
            alt="recipes with sama olive oil"
          />
        </div>
        <div className="w-full lg:w-[50%] h-[full] flex flex-col items-center justify-center gap-y-8 bg-brand font-zarid text-green-primary px-4 lg:px-12 py-12">
          <span className="bg-white text-2xl px-6 py-1 border border-green-primary capitalize">
            {data.tag}
          </span>
          <h1 className="font-siriRegular text-4xl ss:text-5xl text-center leading-none">
            {data.name}
          </h1>
          <p className="text-2xl ss:text-3xl leading-[1] ss:leading-[1] text-center ">
            {data.description}
          </p>
        </div>
      </div>
    </section>
  );
};

export default BlogHero;
