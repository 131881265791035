import { Fragment, useState } from "react";

// Components
import Logo from "./Logo";
import DesktopNavigation from "./Desktop/DesktopNavigation";
import MobileNavBtn from "./Mobile/MobileNavBtn";
import MobileNavigation from "./Mobile/MobileNavigation";

const Navigation = ({ blog }) => {
  const [isActive, setIsActive] = useState(false);

  const MobileMenuHandler = () => {
    setIsActive((active) => !active);
  };

  return (
    <Fragment>
      <div
        className={
          "navigation flex items-center justify-between relative  h-[4.5rem] lg:h-[4.8rem]"
        }
      >
        <Logo blog={blog} />

        <MobileNavBtn
          blog={blog}
          onShowMenu={MobileMenuHandler}
          isActive={isActive}
        />

        <DesktopNavigation blog={blog} />
      </div>
      <MobileNavigation isActive={isActive} onShowMenu={MobileMenuHandler} />
    </Fragment>
  );
};

export default Navigation;
