import { Link } from "react-router-dom";

import { footerLinks } from "../../../constant/Footer";
import styles from "../../../style";

const FooterLinks = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="flex-1 flex flex-col justify-between py-10 lg:py-0 lg:pl-10 ">
      <div
        className={`w-[full] grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-12 justify-between `}
      >
        {footerLinks.map((link) => (
          <ul key={link.title}>
            <li className={`font-zarid ${styles.textMD}  capitalize mb-3`}>
              {link.title}
            </li>
            {link.links.map((link) => (
              <li
                key={link.id}
                className={`font-zaridLight font-light ${styles.textSMLight}  mb-1`}
              >
                <Link
                  className="hover:border-b border-dashed border-green-primary transition-all duration-100 ease-in min-w-max"
                  to={link.link}
                  onClick={scrollToTop}
                >
                  {link.name}
                </Link>
                <a
                  href={link.go}
                  rel="noreferrer"
                  target="_blank"
                  className="hover:border-b border-dashed border-green-primary transition-all duration-100  min-w-max"
                >
                  {link.social}
                </a>
              </li>
            ))}
          </ul>
        ))}
      </div>
      <div
        className={`flex items-center justify-between font-zaridLight font-light ${styles.textBaseLight} pt-10 lg:pt-0 lg:pb-5`}
      >
        <p>@ Sama 2022</p>

        <a
          href="https://wa.me/96171121461"
          target="{_blank}"
          className=" font-zaridLight font-light text-xl text-green-primaryLight "
        >
          Powered by <span className="underline decoration-1">Fady Khalil</span>
        </a>
      </div>
    </div>
  );
};

export default FooterLinks;
