import FooterContent from "./FooterContent";
import FooterLinks from "./FooterLinks";

// styles
import styles from "../../../style";
const FooterSection = () => {
  return (
    <section className="radial-background">
      <div
        className={`flex flex-col lg:flex-row lg:py-10  border-b ${styles.borderDashed}`}
      >
        <FooterContent />
        <FooterLinks />
      </div>
    </section>
  );
};

export default FooterSection;
