import { FaqsHero, FaqsBody } from "../pages/FAQs";

const Faqs = () => {
  return (
    <main className="bg-background-primary">
      <FaqsHero />
      <FaqsBody />
    </main>
  );
};

export default Faqs;
