const styles = {
  SMspacingY: "pt-10 sm:pt-16 md:pt-20",
  spacingY: "pt-20 md:pt-32",
  spacingYM: "mt-20 md:mt-32",

  // ///////////////////////////
  textSM: "text-xl text-green-primary",
  textBase: "text-2xl text-green-primary",
  textMD: "text-[1.5rem] md:text-[1.625rem] text-green-primary leading-tight ",
  textXL: "text-4xl text-green-primary",
  textSMLight: "text-xl text-green-primaryLight",
  textBaseLight: "text-2xl text-green-primaryLight ",
  textMDLight:
    "text-[1.5rem] md:text-[1.625rem] text-green-primaryLight leading-tight ",
  textXLLeight: "text-4xl text-green-primaryLight",

  header:
    "font-siriRegular text-green-primary text-[2rem] ss:text-4xl sm:text-5xl lg:text-[3.2rem] leading-tight text-center md:text-start  mb-6 lg:mb-12",

  // ///////////////////////////
  border: "border border-green-primary rounded-md",
  borderDashed: "border-dashed border-green-primary",
  borderIcon: "flex items-center border border-green-primary p-2 rounded-md",

  // Shop page
  shopLink:
    "group/product flex flex-col items-center justify-end text-center font-zarid text-green-primary text-[1.4rem] leading-none transition ease-in duration-100 border border-background-transparent hover:border-green-primaryLighter",
  shopBtn:
    "mt-2 capitalize border border-green-primary py-2 w-full transition ease-in duration-300 group-hover/product:bg-green-primary group-hover/product:text-background-primary ",
};

export default styles;

export const productStyle = {
  prodcutsCardContainer:
    "flex items-center justify-center flex-col md:flex-row w-[100%] lg:w-[80%] gap-y-12 md:gap-y-0 md:gap-x-8 lg:gap-x-20 mx-auto mt-12",

  productHeader:
    "font-siriRegular text-[2.2rem] sm:text-[2.8rem] md:text-[3.2rem] lg:text-[3.2rem] leading-tight text-center text-green-primary inline",

  box: "group/item lg:relative inline",

  productBtn:
    "italic relative z-100 lg:pointer-events-none underline decoration-[1.5px] decoration-dashed decoration-green-primary underline-offset-[3px]",
  productCloseBtn: "text-6xl z-[100]",
  productDot:
    "inline-block bg-accent border border-green-primary rounded-full  w-[0.7rem] h-[0.7rem] ss:w-[0.8rem] ss:h-[0.8rem] translate-y-[-1.2rem] md:translate-y-[-1.8rem]",

  productContent:
    "font-zarid text-3xl lg:text-2xl leading-[1.1] lg:leading-[1.1] flex flex-col items-center justify-center bg-white transition-transform ease-out  lg:duration-300 ",
  productContentActive:
    "fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[100] gap-y-10 px-10 h-[100vh] w-[100vw] pointer-events-auto opacity-100 select-auto",
  productContentDeactive:
    "absolute left-0 top-[120%] w-[100%] opacity-0 pointer-events-none select-none z-[1] drop-shadow-lg p-4 lg:group-hover/item:opacity-100 lg:group-hover/item:top-[20%] lg:pointer-events-none",
};
