import { useState } from "react";
import placeHolderImage from "../../assests/images/Shop/bottlePlaceholer.png";

const CartItem = (props) => {
  const {
    item,
    handleRemove,
    handleIncreaseQuantity,
    handleDeacreseQunatity,
    hide,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const onLoadHandler = () => {
    setIsLoading(false);
  };
  return (
    <div
      key={item.id}
      className="flex items-center w-full gap-x-4 py-4 border-b border-dashed border-green-primary "
    >
      <div className="h-[6.8rem] w-[6.8rem] ss:w-[7.4rem]  flex items-center justify-center ">
        {isLoading ? (
          <img
            onLoad={onLoadHandler}
            src={placeHolderImage}
            alt=""
            className="h-full w-full object-cover"
          />
        ) : (
          <img
            onLoad={onLoadHandler}
            src={item.image}
            alt=""
            className="h-full w-full object-cover"
          />
        )}
      </div>

      <div className="font-siriRegular text-[18px] text-green-primary w-full pr-2 flex flex-col gap-y-2">
        <p className="">{item.name}</p>

        <span className="flex items-center justify-between">
          <p className="">Size: {item.size}</p>

          {!hide && (
            <button className="text-red-600" onClick={() => handleRemove(item)}>
              remove
            </button>
          )}
        </span>

        <div className="flex items-center justify-between ">
          <div className="flex items-center gap-x-4 ">
            <p className="leading-[0]">QTY: {item.quantity}</p>

            {!hide && (
              <span className="flex items-center gap-x-2">
                <button
                  onClick={() => handleIncreaseQuantity(item)}
                  className="flex items-center  text-[22px]"
                >
                  <ion-icon name="add-circle-outline"></ion-icon>
                </button>
                <button
                  onClick={() => handleDeacreseQunatity(item)}
                  className="flex items-center  text-[22px]"
                >
                  <ion-icon name="remove-circle-outline"></ion-icon>
                </button>
              </span>
            )}
          </div>
          <p>{item.price}</p>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
