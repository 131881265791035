export const meetSama = {
  header: "Anyone who cooks should use olive oil.",
  text: " Every kitchen must have access to high-quality, fresh olive oil as well as reasonably priced. Our pure and natural oils are excellent for doing just that, and they come in a simple flavored bottle.",
};

export const sourcing = {
  header: "Right here, picked, squeezed and bottled here",
  title: "koura, lebanon",
  text: " Koura is known for its olive tree cultivation and olive oil production in all of Lebanon and in the world. The majority of olive oil sold is a combination of old, low-quality oils. We are 100% SOURI olives from Koura, Lebanon.",
};

export const ourProcess = {
  header: "Our process takes time",

  headerOne:
    "The olives are hand harvested in October before they fully mature.",
  discriptionOne: "“Bold حاد”",

  headerTwo:
    "When the olives are more mature, the harvest is made between December and January.",
  discriptionTwo: "“Fruity معتدل”",
};

export const ourPromise = {};

export const theTeam = {
  header: " Sincerely, The Sama Team",
  mainHeader:
    "As nice as our lands are, at the end of the day we're here because we are passionate about food and believe that everything tastes better with olive oil.",
};
