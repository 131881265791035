// recipes
import fattoush from "../../assests/images/Recipes/fatoush.jpg";
import fatoushTwo from "../../assests/images/Recipes/fatoushTwo.jpg";
import quinawa from "../../assests/images/Recipes/quinawa.jpg";
import quinawaTwo from "../../assests/images/Recipes/quinawaTwo.jpg";
import magluba from "../../assests/images/Recipes/magluba.jpg";
import maglubaTwo from "../../assests/images/Recipes/maqlubaTwo.jpg";
import pasta from "../../assests/images/Recipes/pasta.jpg";
import pastaTwo from "../../assests/images/Recipes/pastaTwo.jpg";

const QuinoaHalloumiSalad = {
  name: "Fall Quinoa Halloumi Salad",
  tag: "recipe",
  image: quinawa,
  imageTwo: quinawaTwo,
  description:
    "orem Ipsum is simply dummy text of the printing and typesetting ",
  activeTime: "15 min",
  fullTime: "20 min",
  serve: "6",

  ingredients: [
    {
      quantity: "1 1/4  ",
      item: "cups red quinoa",
    },
    {
      quantity: "2",
      item: " medium fennel bulbs",
    },
    {
      quantity: "1",
      item: "large Gala apple",
    },
    {
      quantity: "Lemon juice for fennel and apple",
      item: "",
    },
    {
      quantity: "125g",
      item: "baby arugula",
    },
    {
      quantity: "1",
      item: "cup pomegranate arils",
    },
    {
      quantity: "1/2",
      item: "cup chopped fennel fronds",
    },
    {
      quantity: "1/3",
      item: "cup roughly chopped walnuts",
    },
    {
      quantity: "1/3",
      item: "cup roughly chopped walnuts",
    },
    {
      quantity: "1",
      item: "tbsp Sama Extra Virgin Olive Oil",
    },
    {
      quantity: "200g",
      item: "Halloumi slices",
    },
    // dressing
    {
      quantity: "1",
      item: " small shallot, finely chopped",
    },
    {
      quantity: "2",
      item: "tbsp Dijon mustard",
    },
    {
      quantity: "3",
      item: " tbsp lemon juice",
    },
    {
      quantity: "1",
      item: "tbsp honey",
    },
    {
      quantity: "2",
      item: "tbsp apple cider vinegar",
    },
    {
      quantity: "7",
      item: "tbsp Sama Extra Virgin Olive Oil",
    },
    {
      quantity: "Salt to taste",
      item: "",
    },
    {
      quantity: "Black pepper to taste",
      item: " ",
    },
  ],

  instructions: [
    {
      stepNumber: "1",
      description:
        "Cook the quinoa: Rinse the quinoa well and drain. Transfer to a pot along with 2 1/2 cups of water and a pinch of salt. Bring to a boil, lower the heat, and simmer uncovered for 10 to 15 minutes or until the quinoa are cooked through. Remove from heat, put the lid on, and let the quinoa steam for 5 minutes. Uncover, fluff with a fork and let it cool.",
    },
    {
      stepNumber: "2",
      description:
        "Chop the salad: Peel the fennel bulbs, cut in half, and finely slice. Cut the apple into bite sized pieces. Transfer the fennel and apple to bowls, generously squeeze lemon on them and toss. This will keep them from browning. ",
    },
    {
      stepNumber: "3",
      description:
        "Prepare the dressing: To a small bowl, add the chopped shallot, mustard, lemon juice, honey, and vinegar. Slowly drizzle in the extra virgin olive oil while whisking. Whisk until the sauce is creamy. Season with salt and black pepper. ",
    },
    {
      stepNumber: "4",
      description:
        "Pan-fry the Halloumi: Heat a non-stick skillet over medium heat. Add 1 tbsp of extra virgin olive oil and pam-fry the Halloumi slices for a couple of minutes on each side, or until the cheese is golden. Don’t over cook.",
    },
    {
      stepNumber: "5",
      description:
        "Serve: Layer the salad ingredients in your serving plate and drizzle with the dressing. Toss to combine,  taste, adjust the seasoning and acidity. Top with the golden Halloumi. Enjoy!",
    },
  ],
};

const HealthyChickenMaqluba = {
  name: "Healthy Chicken Maqluba ",
  tag: "recipe",
  image: magluba,
  imageTwo: maglubaTwo,
  description:
    "orem Ipsum is simply dummy text of the printing and typesetting ",
  activeTime: "30 min",
  fullTime: "90 min",
  serve: "8",

  ingredients: [
    {
      quantity: "1 1/2 Kg ",
      item: "chicken thighs",
    },
    {
      quantity: "Salt to taste",
    },
    {
      quantity: "Black pepper to taste",
    },
    {
      quantity: "2 tsp ",
      item: "7 spice mix",
    },
    {
      quantity: "1/2 tsp ",
      item: "ground cardamon",
    },
    {
      quantity: "1 tbsp ",
      item: "Sama Extra Virgin Olive Oil ",
    },
    {
      quantity: "1",
      item: "large red onion, peeled and quartered ",
    },
    {
      quantity: "6 ",
      item: "garlic cloves, peeled ",
    },
    {
      quantity: "1",
      item: "large slice of fresh ginger ",
    },
    {
      quantity: "2",
      item: "bay leaves ",
    },
    {
      quantity: "1",
      item: "large cinnamon stick",
    },
    {
      quantity: "2",
      item: "loumi",
    },
    {
      quantity: "8",
      item: "cloves",
    },
    {
      quantity: "1",
      item: "large chili pod",
    },
    {
      quantity: "5",
      item: "cups water",
    },

    // dressing
    {
      quantity: "2",
      item: " medium potatoes ",
    },
    {
      quantity: "3",
      item: "large eggplants ",
    },
    {
      quantity: "2",
      item: " medium tomatoes ",
    },
    {
      quantity: "1",
      item: "large red onion, peeled",
    },
    {
      quantity: "1",
      item: "large bell pepper",
    },
    {
      quantity: "2-3",
      item: "small chili pods",
    },
    {
      quantity: "Salt to taste ",
    },
    {
      quantity: "Black pepper to taste",
    },
    {
      quantity: "1/4",
      item: "cup Sama Extra Virgin Olive Oil, for brushing",
    },
    //
    {
      quantity: "1/2 kg",
      item: "basmati rice, soaked for 1 hour",
    },
    {
      quantity: "Chicken broth prepared before",
    },
    {
      quantity: "1 tsp ",
      item: "7 spice mix",
    },
    {
      quantity: "1 tsp",
      item: " turmeric ",
    },
    {
      quantity: "1 tsp",
      item: " unsalted butter ",
    },

    //
    {
      quantity: "1/4",
      item: " cup toasted almonds ",
    },
    {
      quantity: "Chopped parsley ",
    },
    {
      quantity: "A drizzle of Sama Extra Virgin Olive Oil",
    },
  ],

  instructions: [
    {
      stepNumber: "1",
      description:
        "Prepare the chicken and broth: Season the chicken thighs on both sides with salt, black pepper, 7 spice mix, and cardamon. Place a large pot on medium heat and let it warm up, then drizzle in the extra virgin olive oil. Pan-fry the chicken in batches on both sides until lightly browned. Add the onion, garlic, ginger, bay leaves, cinnamon stick, loumi, cloves, chili, and water. Let it come to a boil while removing the scum that forms on the surface. Once the liquid is clear, close the lid, and simmer for 45 minutes. Later, strain the broth and set aside. Shred the chicken into bite-sized pieces and set aside. Discard the skins and bones.",
    },
    {
      stepNumber: "2",
      description:
        ". Roast the veggies:  Meanwhile, preheat the oven to 220°C and line large baking sheets with parchment paper. Peel the potatoes and cut them into 1 cm thick slices. Cut the eggplants lengthwise into 1 cm slices. You can peel some of the skin if you prefer. Cut the tomatoes, onion, and bell pepper into 1 cm slices.  Arrange the potato and eggplant slices on the prepared baking sheets. Season with salt and black pepper and generously brush with extra virgin olive oil. Roast in the oven for 35 minutes or until tender. During the last 10 minutes, crank up the heat on high and place the tomato, onion, bell pepper, and chili on a prepared baking sheet on the lower oven rack. Turn the heat off.",
    },
    {
      stepNumber: "3",
      description:
        "Prepare the rice: Wash the soaked rice twice and drain. Transfer to a large heat proof bowl. Add the warm chicken broth, the 7 spice mix, turmeric, and butter. Stir until the spices and butter are dissolved. It’s very important to taste the broth to make sure that it’s well seasoned. Add salt if needed. ",
    },
    {
      stepNumber: "4",
      description:
        ". Assemble and cook the Maqluba: Time to assemble the Maqluba! Use a large pot, approximately 25cm diameter * 10cm height. Brush the pot with some extra virgin olive oil, and you can line its bottom with a circular parchment paper piece. Layer the roasted chili, tomato, onion, bell pepper, chicken pieces, and potatoes. Pat down firmly using a wooden spoon. Now start arranging the roasted eggplant, while overlapping them and letting them hang from the pot. You should create a “fan” with the eggplant slices around the pot. Now Add the rice along with the seasoned broth. Pat down the rice firmly. Close the hanging eggplant slices over the rice. Close the lid and place the pot on medium heat. Once it boils, lower the heat, and simmer for around 45 minutes or until all the broth is absorbed. Keep the lid on for 15 minutes before flipping.  ",
    },
    {
      stepNumber: "5",
      description:
        "Flip and serve: Get a large serving plate with slightly raised edges and place it on the pot. Use a kitchen cloth to hold the handles and keep the plate close to the pot. Carefully flip the Maqluba, tap all over the bottom of the pot, and slowly remove it. The Maqluba should come out like a cake! Finish with a generous drizzle of extra virgin olive oil, toasted almonds, and chopped parsley. Enjoy!",
    },
  ],
};

const Fattoush = {
  name: "Fattoush With Baked Sea Bream",
  tag: "recipe",
  image: fattoush,
  imageTwo: fatoushTwo,
  description:
    "lorem Ipsum is simply dummy text of the printing and typesetting ",
  activeTime: "20 min",
  fullTime: "30 min",
  serve: "4",

  ingredients: [
    {
      quantity: "2",
      item: " medium pita bread loaves",
    },
    {
      quantity: "A pinch of salt",
    },
    {
      quantity: "1",
      item: "tbsp Sama Extra Virgin Olive oil",
    },
    //
    {
      quantity: "2",
      item: "medium tomatoes ",
    },
    {
      quantity: "3",
      item: " cucumbers ",
    },
    {
      quantity: "6",
      item: "radishes",
    },
    {
      quantity: "3",
      item: "Spring onions",
    },
    {
      quantity: "1/2",
      item: "lettuce ",
    },
    {
      quantity: "8",
      item: "sprigs of purslane ",
    },
    {
      quantity: "1/2",
      item: "bunch parsley",
    },
    {
      quantity: "2",
      item: "cups baby arugula",
    },
    //
    {
      quantity: "3 tbsp",
      item: "Sama Extra Virgin Olive oil",
    },
    {
      quantity: "1",
      item: "garlic clove, crushed ",
    },
    {
      quantity: "2 1/2",
      item: "tbsp lemon juice ",
    },
    {
      quantity: "1 tbsp",
      item: "Sama Pomegranate Molasses",
    },
    {
      quantity: "1 tsp",
      item: "sumac",
    },
    {
      quantity: "1/2 tsp",
      item: "dried mint ",
    },
    {
      quantity: "Salt to taste ",
    },
    //
    {
      quantity: "2 tbsp",
      item: "Sama Extra Virgin Olive oil",
    },
    {
      quantity: "2",
      item: "garlic cloves, crushed ",
    },
    {
      quantity: "1/4",
      item: "tsp black pepper ",
    },
    {
      quantity: "1 1/2 tbsp ",
      item: "lemon zest",
    },
    {
      quantity: "Hot paprika to taste ",
    },
    {
      quantity: "4",
      item: "small Sea Bream ",
    },
    {
      quantity: "Salt to taste ",
    },
    {
      quantity: "12",
      item: "lemon slices, seeds removed ",
    },
  ],

  instructions: [
    {
      stepNumber: "1",
      description:
        "Toast the bread: Preheat the oven to 220°C and line a large baking sheet with parchment paper. Cut the pita into thin strips, drizzle extra virgin olive oil, sprinkle a pinch of salt, and mix using your hands. Spread them on the baking sheet and bake in the oven for about 10 minutes, or until the bread the golden. ",
    },
    {
      stepNumber: "2",
      description:
        "Prepare the Fattoush: Chop the tomatoes and cucumber into bite sized pieces. Thinly slice the radishes and Spring onions. Tear the lettuce, pick the purslane leaves, and finely chop the parsley. Place all the veggies in a large serving bowl.",
    },
    {
      stepNumber: "3",
      description:
        "    Prepare the dressing: Place the dressing ingredients in a jar, close, and shake really well. Taste and adjust it to your liking. You can adjust the seasoning after you dress the Fattoush.",
    },
    {
      stepNumber: "4",
      description:
        "Bake the fish:  Prepare the sauce to brush the fish: in a small bowl, mix together the extra virgin olive oil, crushed garlic, black pepper, lemon zest, and hot paprika. One by one, generously season with salt and brush the Sea Bream from the inside and outside. Place 3 lemon slices inside each fish. Line a large baking sheet with parchment paper and arrange the Sea Bream on it. Bake in the preheated oven for 15 to 20 minutes.",
    },
    {
      stepNumber: "5",
      description:
        "Serve: Pour the dressing over the Fattoush, gently toss the veggies, taste, and adjust the seasoning. Top with the golden toasted bread. Finish with pomegranate seeds and a generous drizzle of pomegranate molasses. Serve with the baked Sea Bream along lemon wedges for squeezing. Enjoy!",
    },
  ],
};
const Pasta = {
  name: "Salsa Verde Pasta With Roasted Tomato",
  tag: "recipe",
  image: pasta,
  imageTwo: pastaTwo,
  description:
    "lorem Ipsum is simply dummy text of the printing and typesetting ",
  activeTime: "15 min",
  fullTime: "20 min",
  serve: "6",

  ingredients: [
    {
      quantity: "600g",
      item: "tomatoes on the vine",
    },
    {
      quantity: "3",
      item: "garlic cloves ",
    },
    {
      quantity: "A bunch of basil leaves",
    },
    {
      quantity: "Salt to taste ",
    },
    {
      quantity: "Black pepper to taste ",
    },
    {
      quantity: "2 tbsp",
      item: " Sama Extra Virgin Olive Oil",
    },
    //
    {
      quantity: "2 tbsp",
      item: "capers",
    },
    {
      quantity: "1 tbsp",
      item: "caper brine ",
    },
    {
      quantity: "1",
      item: " medium bunch of parsley, large stalks trimmed",
    },
    {
      quantity: "Salt to taste ",
    },
    {
      quantity: "Black pepper to taste",
    },
    {
      quantity: "2/3",
      item: "cup Sama Extra Virgin Olive Oil",
    },
    {
      quantity: "2",
      item: "cups packed basil leaves, large stalks trimmed",
    },
    {
      quantity: "1 tbsp ",
      item: "lemon zest",
    },
    {
      quantity: "2 tbsp ",
      item: "lemon juice",
    },
    //
    {
      quantity: "400g ",
      item: "short pasta ",
    },
    {
      quantity: "Water to cook the pasta ",
    },
    {
      quantity: "1/4",
      item: "cup grated Parmesan, plus more to serve",
    },
    {
      quantity: "Basil leaves to garnish ",
    },
  ],

  instructions: [
    {
      stepNumber: "1",
      description:
        "Roast the tomatoes: Preheat the oven to 200°C and line a baking sheet with parchment paper. Place the tomatoes, garlic, and basil on the baking sheet. Season to taste and drizzle the extra virgin olive oil. Roast for 15 minutes or until the tomatoes split. Keep warm in the oven while you prepare the sals ",
    },
    {
      stepNumber: "2",
      description:
        "Roast the tomatoes: Preheat the oven to 200°C and line a baking sheet with parchment paper. Place the tomatoes, garlic, and basil on the baking sheet. Season to taste and drizzle the extra virgin olive oil. Roast for 15 minutes or until the tomatoes split. Keep warm in the oven while you prepare the salsa",
    },
    {
      stepNumber: "3",
      description:
        "    Cook the pasta:  Cook the pasta in generously salted water according to the packet instructions. Drain but reserve 1/2 cup of cooking water.",
    },
    {
      stepNumber: "4",
      description:
        "  Serve: Transfer the pasta to your serving plate. Add the salsa verde with a bit of cooking water and the Parmesan. Gently mix until the pasta is coated. Add more cooking water if you need to. Top with the roasted tomatoes and drizzle with their juices. Garnish with basil leaves, serve with extra Parmesan, and enjoy!",
    },
  ],
};

export const recipesBlog = [
  QuinoaHalloumiSalad,
  HealthyChickenMaqluba,
  Fattoush,
  Pasta,
];
