import fruityBottle from "../assests/images/productComponent/fruityBottle.png";
import boldBottle from "../assests/images/productComponent/boldBottle.png";
import productVideoOne from "../assests/images/productComponent/SAMAChicken.mp4";
import productVideoTwo from "../assests/images/productComponent/SAMAHummus.mp4";

export const products = [
  {
    image: boldBottle,
    video: productVideoTwo,
    name: ["Strong", "''Bold''", "750Ml"],
    title: "Extra virgin olive oil",
    description: "Made for eating, never heating.",
    id: 1,
  },
  {
    image: fruityBottle,
    video: productVideoOne,
    name: [" Smooth", "''Fruity''", "500Ml"],
    title: "Extra virgin olive oil",
    description: "Use It every day, in every way.",
    id: 2,
  },
];
