// UI
import Container from "../../components/container/Container";
// components
import PaymentForm from "../../form/PaymentForm";
import CartCheckout from "./components/CartCheckout";
// styles
import styles from "../../style";

const CheckoutPage = () => {
  return (
    <main className={`bg-background-primary ${styles.SMspacingY}`}>
      <Container>
        <div className="md:w-[80%] mx-auto lg:w-full flex flex-col lg:flex-row gap-y-14">
          <div className="order-2 lg:order-1 flex-1 lg:border-r lg:border-green-primary">
            <PaymentForm />
          </div>

          <div className="order-1 lg:order-2 flex-1">
            <CartCheckout />
          </div>
        </div>
      </Container>
    </main>
  );
};

export default CheckoutPage;
