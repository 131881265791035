// UI
import Container from "../components/container/Container";
// Componenys
import { Location, Form } from "../pages/Contact/index";

const Contact = () => {
  return (
    <main className="bg-background-primary">
      <Container>
        <Form />
        <Location />
      </Container>
    </main>
  );
};

export default Contact;
