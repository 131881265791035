const LocationComponent = (props) => {
  return (
    <div
      key={props.id}
      className={
        "flex flex-col items-center justify-center gap-y-6  lg:gap-y-0 lg:flex-row lg:justify-evenly border-t border-dashed border-green-primary py-6"
      }
    >
      <div className="flex-1 flex flex-col items-center text-center lg:items-start lg:text-start lg:flex-row  lg:gap-2 font-zarid">
        <span className="text-4xl">
          <ion-icon name="location-outline"></ion-icon>
        </span>
        <span>
          <p className="text-[1.6rem] leading-none text-green-primary lg:mb-[4px]">
            {props.country}
          </p>
          <p className="text-xl leading-[1.2] text-green-primary px-8 sm:px-14 lg:px-0">
            {props.street}
          </p>
        </span>
      </div>
      <div className="flex-1 flex items-center justify-center font-zarid ">
        <a
          href={props.emailLink}
          target={"_blank"}
          rel="noreferrer"
          className="text-green-primary text-[22px] hover:underline hover:decoration-green-primary hover:decoration-dashed hover:decoration-1"
        >
          {props.email}
        </a>
      </div>
      <div className="flex-1 flex flex-col sm:flex-row gap-x-4 md:flex-col md:gap-x-0 items-center justify-center font-zarid ">
        {props.number.map((num, i) => (
          <a
            key={i}
            className="text-xl"
            href={`tel:${num}`}
            target={"_blank"}
            rel="noreferrer"
          >
            {`phone:`} {num}
          </a>
        ))}
      </div>
    </div>
  );
};

export default LocationComponent;
