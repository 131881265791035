import { logo } from "../../assests/images";

const BigLogo = (props) => {
  const classes = "w-[100%] h-[auto] object-contain " + props.className;
  return (
    <div>
      <img src={logo} alt="Sama company logo" className={classes} />
    </div>
  );
};

export default BigLogo;
