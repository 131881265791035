import styles from "../../style";
import ContactForm from "../../form/ContactForm";
const Form = () => {
  return (
    <section className={`${styles.SMspacingY}`}>
      <h1 className="font-siriRegular text-green-primary text-4xl md:text-5xl text-center pb-6">
        Feel free to get in touch
      </h1>

      <ContactForm />
    </section>
  );
};

export default Form;
