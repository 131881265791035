import { useEffect, useState } from "react";
// custom Hook
import useGetData from "../../../../hooks/use-get-data";
// UI components
import Button from "../../../../components/UI/Button/Button";
// fetching data
import getHomePageContent from "../../../../services/fetchingData/fetchHomePageContent";
import { Default_HOME_Content } from "../../../../services/defaultData/defaultHomePageContent";

const HeroContent = () => {
  const [heroData, setHeroData] = useState("");
  const data = useGetData(getHomePageContent);

  useEffect(() => {
    if (!data) {
      setHeroData(Default_HOME_Content.Hero);
    }

    if (data) {
      setHeroData(data.hero[0]);
    }
  }, [data]);

  const { HeroHeader, HeroButton } = heroData;

  const header = HeroHeader;
  const button = HeroButton;

  return (
    <div className="absolute bottom-6 z-[2] w-[90%] md:w-[60%] lg:w-[50%] xl:w-[40%]  text-white">
      <h1 className="font-siriMedium text-5xl sm:text-6xl ">{header}</h1>

      <Button
        to={"shop"}
        data={button}
        className={
          "font-zarid text-3xl py-6 md:py-5 px-2  my-4 w-[100%] lg:w-[60%]  "
        }
      />
    </div>
  );
};

export default HeroContent;
