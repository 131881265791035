import { Link } from "react-router-dom";

const MobileNavigation = ({ isActive, onShowMenu }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div
      className={`w-[100vw] h-[100vh] bg-brand z-[3] fixed top-[0%]
      transition-all ease-in-out duration-300
        ${
          isActive
            ? "left-[50%]  -translate-x-1/2 opacity-100"
            : "left-[-100%] opacity-0"
        } overscroll-y-contain`}
    >
      <nav>
        <ul
          className={`flex flex-col items-center justify-center mt-8 font-siriRegular gap-8 text-4xl text-green-primary uppercase tracking-wide h-[100vh] w-[100%]  overscroll-y-none`}
        >
          <li>
            <Link
              onClick={() => {
                scrollToTop();
                onShowMenu();
              }}
              to={"/"}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                scrollToTop();
                onShowMenu();
              }}
              to={"about-us"}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                scrollToTop();
                onShowMenu();
              }}
              to={"blog/recipes"}
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                scrollToTop();
                onShowMenu();
              }}
              to={"shop"}
            >
              Shop
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                scrollToTop();
                onShowMenu();
              }}
              to={"faqs"}
            >
              FAQs
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                scrollToTop();
                onShowMenu();
              }}
              to={"contact"}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MobileNavigation;
