import React from "react";
import { useState } from "react";

const Slider = (props) => {
  const { slides } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const previouSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const nextSlide = () => {
    const isLastSLide = currentIndex === slides.length - 1;
    const newIndex = isLastSLide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="w-full">
      <div>
        <p className="font-zarid text-[1.7rem] text-green-primary text-center leading-none mt-2">
          {slides[currentIndex]}
        </p>
      </div>
      <div className="flex items-center justify-between mt-6  border border-green-primary text-xl">
        <button
          onClick={previouSlide}
          className="w-full p-[0.3rem] flex items-center justify-center transition duration-100 ease-in  hover:bg-green-primary hover:text-brand border-r border-green-primary"
        >
          <ion-icon name="chevron-back-outline"></ion-icon>
        </button>
        <button
          onClick={nextSlide}
          className="w-full p-[0.3rem] flex items-center justify-center transition duration-100 ease-in hover:bg-green-primary hover:text-brand"
        >
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </button>
      </div>
    </div>
  );
};

export default Slider;
