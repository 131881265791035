import { createContext, useState, useReducer } from "react";
export const CartShowContext = createContext();
export const CartItemsContext = createContext();

const defaultCartState = {
  items: [],
  totalAmount: 0,
};

const cartReducer = (state, action) => {
  if (action.type === "ADD_ITEM") {
    const newItem = action.item;
    const existingItemIndex = state.items.findIndex(
      (item) => item.sku === newItem.sku && item.id === newItem.id
    );

    if (existingItemIndex !== -1) {
      const existingItem = state.items[existingItemIndex];

      const updatedItem = {
        ...existingItem,
        quantity: existingItem.quantity + newItem.quantity,
        price: `${
          existingItem.basePrice * (existingItem.quantity + newItem.quantity)
        } ${existingItem.price.split(" ")[1]}`,
      };
      const updatedItems = [...state.items];
      updatedItems[existingItemIndex] = updatedItem;

      // Calculate the total amount
      const totalAmount = updatedItems.reduce((acc, item) => {
        const price = parseFloat(item.price.split(" ")[0]);
        return acc + price;
      }, 0);

      return {
        ...state,
        items: updatedItems,
        totalAmount: `${totalAmount.toFixed(2)} ${
          updatedItem.price.split(" ")[1]
        }`,
      };
    } else {
      // Item does not exist, add it to the cart
      const updatedItem = {
        ...newItem,
      };

      const updatedItems = [...state.items, updatedItem];

      // Calculate the total amount
      const totalAmount = updatedItems.reduce((acc, item) => {
        const price = parseFloat(item.price.split(" ")[0]);
        return acc + price;
      }, 0);

      return {
        ...state,
        items: updatedItems,
        totalAmount: `${totalAmount.toFixed(2)} ${
          updatedItem.price.split(" ")[1]
        }`,
      };
    }
  }
  if (action.type === "REMOVE_ITEM") {
    const itemToRemove = action.item;
    const updatedItems = state.items.filter(
      (item) => item.sku !== itemToRemove.sku || item.id !== itemToRemove.id
    );

    // Calculate the total amount
    const totalAmount = updatedItems.reduce((acc, item) => {
      const price = parseFloat(item.price.split(" ")[0]);
      return acc + price;
    }, 0);

    return {
      ...state,
      items: updatedItems,
      totalAmount: `${totalAmount.toFixed(2)} ${
        updatedItems.length > 0 ? updatedItems[0].price.split(" ")[1] : ""
      }`,
    };
  }

  if (action.type === "CLEAR_CART") {
    return defaultCartState;
  }

  return defaultCartState;
};

export const CartShopProvider = ({ children }) => {
  // CART SHOWN
  const [cartOpen, setCartOpen] = useState(false);

  // CART CONTEXT
  const [cartState, dispatchCartAction] = useReducer(
    cartReducer,
    defaultCartState
  );

  const addItemToCartHandler = (item) => {
    dispatchCartAction({ type: "ADD_ITEM", item: item });
  };
  const removeItemFromCartHandler = (item) => {
    dispatchCartAction({ type: "REMOVE_ITEM", item: item });
  };

  const CartContex = {
    items: cartState.items,
    addItem: addItemToCartHandler,
    removeItem: removeItemFromCartHandler,
    totalAmount: cartState.totalAmount,
  };

  return (
    <CartShowContext.Provider value={{ cartOpen, setCartOpen }}>
      <CartItemsContext.Provider value={CartContex}>
        {children}
      </CartItemsContext.Provider>
    </CartShowContext.Provider>
  );
};
