export const Default_HOME_Content = {
  Hero: {
    HeroHeader: "The true taste of Lebanon",
    HeroButton: "Shop Now",
  },

  Alert: {
    alertText1: "Now Serving Olives from:  Koura, Lebanon",
    alertText2: "Harvesting Season:  October - January",
  },

  FunFact: {
    text1: "It takes around 5200 Olives just to make 1 bottle of “Sama 500 ML",
    text2:
      "In fact, olives are a fruit, and their oil is the juice. Best is fresh!",
    text3:
      "Olives are rich source of oils, minerals and vitamins A, E, K and B.",
    text4: "An olive tree can live up to 2,000 years.",
  },

  Comparison: {
    comparisonBtn:
      "NO BLENDING. Only 100% Souri Olives from a big Lebanese field.",
  },
};
