import { useContext, useState } from "react";
import { Link } from "react-router-dom";
// styles
import styles from "../../style";
// context
import { UserLocationContext } from "../../context/User-location-context";
import placeHolderImage from "../../assests/images/Shop/bottlePlaceholer.png";

const ProductsGrid = ({ products }) => {
  const [isLoading, setIsLoading] = useState(true);
  const onLoadHandler = () => {
    setIsLoading(false);
  };
  // get user location
  const { userLocation } = useContext(UserLocationContext);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const formatPrice = (price) => {
    const [priceString, currency] = price.split(" ");
    const formatPrice = parseFloat(priceString).toFixed(2);
    return `${formatPrice}  ${currency}`;
  };

  return (
    <section className={styles.SMspacingY}>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 md:gap-x-8 gap-y-14">
        {products.map((product) => (
          <Link
            key={product.name}
            onClick={scrollToTop}
            to={"/shop/displayProducts"}
            state={{
              data: product,
              userLocation: userLocation,
            }}
            className={styles.shopLink}
          >
            {isLoading ? (
              <img
                onLoad={onLoadHandler}
                className="mb-4 "
                src={placeHolderImage}
                alt=""
              />
            ) : (
              <img
                onLoad={onLoadHandler}
                className="mb-4"
                loading="lazy"
                src={product.image}
                alt=""
              />
            )}
            <p>{product.name}</p>
            <p className="text-2xl">{`${
              userLocation === "KW"
                ? formatPrice(product.variants[0].price_KD)
                : formatPrice(product.variants[0].price_USD)
            }`}</p>
            <button className={styles.shopBtn}>View Product</button>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default ProductsGrid;
