import { useState, useEffect, useRef } from "react";
// custom Hook
import useGetData from "../../hooks/use-get-data";

// components
import Slider from "./Slider";
// data
import { factImage, treeImage } from "../../pages/Home/Home-images";
import { placeHolder } from "../../assests/images";
import getHomePageContent from "../../services/fetchingData/fetchHomePageContent";
import { Default_HOME_Content } from "../../services/defaultData/defaultHomePageContent";

const FunFact = ({ className }) => {
  const [funFactData, setFunFactData] = useState("");
  const data = useGetData(getHomePageContent);

  const [imageSrc, setImageSrc] = useState(placeHolder);
  const highResImageRef = useRef(null);

  useEffect(() => {
    if (!data) {
      setFunFactData(Default_HOME_Content.FunFact);
    }

    if (data) {
      setFunFactData(data.funfact);
    }
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setImageSrc(factImage);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (highResImageRef.current) {
      observer.observe(highResImageRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [highResImageRef]);

  const {
    text1 = Default_HOME_Content.FunFact.text1,
    text2 = Default_HOME_Content.FunFact.text2,
    text3 = Default_HOME_Content.FunFact.text3,
    text4 = Default_HOME_Content.FunFact.text4,
  } = funFactData;

  const FunFactSlider = [text1, text2, text3, text4];

  return (
    <section className={className}>
      <div className="flex flex-col md:flex-row w-full lg:h-[500px]  overflow-hidden">
        <div className="w-full md:w-[65%] lg:w-[70%] lg:h-[100%]">
          <img
            className="h-[100%] w-full object-cover"
            src={imageSrc}
            alt="food with sama olive oil bottle "
            ref={highResImageRef}
          />
        </div>
        <div className="w-full md:w-[35%] lg:w-[30%] flex flex-col items-center justify-between gap-y-4 bg-brand py-6 px-4">
          <h2 className="font-siriRegular text-3xl text-green-primary border-b-2 border-dashed  border-green-primary">
            *Fun Fact*
          </h2>
          <img
            className="w-[30%] sm:w-[25%] md:w-[40%] "
            src={treeImage}
            alt="olive tree"
          />
          <Slider slides={FunFactSlider} />
        </div>
      </div>
    </section>
  );
};

export default FunFact;
