import { useState, useEffect } from "react";

const useGetData = (fetchDataFunction) => {
  const [data, setData] = useState("");

  useEffect(() => {
    async function fetchContentData() {
      try {
        const response = await fetchDataFunction();
        setData(response);
      } catch (error) {
        console.log(error.message);
      }
    }
    fetchContentData();
  }, [fetchDataFunction]);

  return data;
};

export default useGetData;
