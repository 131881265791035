import styles from "../style";
import { DisplayProducts } from "../pages/Shop/index";

const DisplayProductsPage = () => {
  return (
    <main className={`bg-background-primary ${styles.SMspacingY}`}>
      <DisplayProducts />
    </main>
  );
};

export default DisplayProductsPage;
