import {
  MeetSama,
  OurPromise,
  Process,
  Sourcing,
  TheTeam,
} from "../pages/AboutUs/index";
const About = () => {
  return (
    <main className=" bg-background-primary">
      <MeetSama />
      <Sourcing />
      <Process />
      <OurPromise />
      <TheTeam />
    </main>
  );
};

export default About;
