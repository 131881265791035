import styles from "../../style";
import LocationComponent from "./LocationComponent";

import { contact } from "../../constant/Contact";
const Location = () => {
  return (
    <div
      className={`${styles.spacingY} border-b border-dashed border-green-primary`}
    >
      {contact.map((cnt, i) => (
        <LocationComponent
          key={i}
          id={cnt.id}
          country={cnt.country}
          street={cnt.street}
          email={cnt.email}
          emailLink={cnt.emailLink}
          number={cnt.number}
        />
      ))}
    </div>
  );
};

export default Location;
