import { useContext, useEffect, useState } from "react";
// custom Hook
import useInput from "../hooks/user-input";
// UI
import Input from "../components/Input/Input";
// context
import { CartItemsContext } from "../context/Cart-shop-context";
import { FormContext } from "../context/form-context";
// forms data and style
import FormInputMessage from "./FormInputMessage";
import formStyle from "./formStyle";

const PaymentForm = () => {
  const [isSubmited, setIsSubmited] = useState(false);
  const [country, setCountry] = useState("Lebanon");

  const cartCTX = useContext(CartItemsContext);
  const formCTX = useContext(FormContext);
  const { items } = cartCTX;
  const { formISValid, setFormISValid } = formCTX;

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const {
    value: contactInput,
    isValid: contactIsValid,
    HasError: contactHasError,
    inputChangeHandler: contactChangeHandler,
    inputBlurHandler: contactBlurHandler,
    reset: contactReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });

  const {
    value: fristNameInput,
    isValid: firstNameIsValid,
    HasError: firstNameHasError,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: firstNameReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: lastNameInput,
    isValid: lastNameIsValid,
    HasError: lastNameHasError,
    inputChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: lastNameReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: addressInput,
    isValid: addressIsValid,
    HasError: addressHasError,
    inputChangeHandler: addressChangeHandler,
    inputBlurHandler: addressBlurHandler,
    reset: addressReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: cityInput,
    isValid: cityIsValid,
    HasError: cityHasError,
    inputChangeHandler: cityChangeHandler,
    inputBlurHandler: cityBlurHandler,
    reset: cityReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: phoneNumberInput,
    isValid: phoneNumberIsValid,
    HasError: phoneNumberHasError,
    inputChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHandler,
    reset: phoneNumberReset,
  } = useInput((value) => /^\d{8,}$/.test(value));

  const {
    value: companyInput,
    isValid: companyIsValid,
    HasError: companyHasError,
    inputChangeHandler: companyChangeHandler,
    inputBlurHandler: companyBlurHandler,
    reset: companyReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: apartmentInput,
    isValid: apartmentIsValid,
    HasError: apartmentHasError,
    inputChangeHandler: apartmentChangeHandler,
    inputBlurHandler: apartmentBlurHandler,
    reset: apartmentReset,
  } = useInput((value) => value.trim("") !== "");
  const {
    value: postalCodeInput,
    isValid: postalCodeIsValid,
    HasError: postalCodeHasError,
    inputChangeHandler: postalCodeChangeHandler,
    inputBlurHandler: postalCodeBlurHandler,
    reset: postalCodeReset,
  } = useInput((value) => /^\d{4,}$/.test(value));

  const products = items.map((product) => {
    return {
      name: product.name,
      size: product.size,
      quantity: product.quantity,
      price: product.price,
    };
  });

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const finalStepHandler = () => {
    if (formISValid) {
      setIsSubmited(true);
      scrollToTop();
    } else {
      alert(
        "Please ensure that the form has been filled out with the appropriate and accurate content"
      );
    }
  };

  const onChange = () => {
    setIsSubmited(false);
  };

  useEffect(() => {
    if (
      contactIsValid &&
      firstNameIsValid &&
      lastNameIsValid &&
      addressIsValid &&
      cityIsValid &&
      companyIsValid &&
      apartmentIsValid &&
      postalCodeIsValid &&
      phoneNumberIsValid &&
      items.length !== 0
    ) {
      setFormISValid(true);
    } else {
      setFormISValid(false);
    }
  }, [
    contactIsValid,
    firstNameIsValid,
    lastNameIsValid,
    addressIsValid,
    cityIsValid,
    phoneNumberIsValid,
    apartmentIsValid,
    items,
    postalCodeIsValid,
    setFormISValid,
    companyIsValid,
  ]);

  const rest = () => {
    firstNameReset();
    lastNameReset();
    contactReset();
    companyReset();
    addressReset();
    apartmentReset();
    cityReset();
    postalCodeReset();
    phoneNumberReset();
  };

  const formSubmitionHandler = async (event) => {
    event.preventDefault();

    if (!formISValid) return;

    const formData = {
      name: fristNameInput,
      email: contactInput,
      country_input: country,
      country: country,
      first_name: fristNameInput,
      last_name: lastNameInput,
      company: companyInput,
      apartment: apartmentInput,
      city: cityInput,
      postal_code: cityInput,
      phone: phoneNumberInput,
      products: products,
    };

    fetch("https://submissions.samapremium.co/public/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          alert("Form submitted successfully!");
          rest();
        } else {
          alert("Form submission failed.");
        }
      })
      .catch((error) => {
        console.error("An error occurred while submitting the form:", error);
      });
  };

  function getStyledInput(inputStyle, hasError) {
    return `${inputStyle} ${hasError ? `${formStyle.errorInput}` : ""}`;
  }
  const contactStyle = getStyledInput(formStyle.input, contactHasError);
  const postalCodeStyle = getStyledInput(formStyle.input, postalCodeHasError);
  const firstNameStyle = getStyledInput(formStyle.input, firstNameHasError);
  const lastNameStyle = getStyledInput(formStyle.input, lastNameHasError);
  const addressStyle = getStyledInput(formStyle.input, addressHasError);
  const cityStyle = getStyledInput(formStyle.input, cityHasError);
  const phoneNumberStyle = getStyledInput(formStyle.input, phoneNumberHasError);
  const apartmentStyle = getStyledInput(formStyle.input, apartmentHasError);
  const companyStyle = getStyledInput(formStyle.input, companyHasError);

  return (
    <div>
      <form onSubmit={formSubmitionHandler} className="lg:w-[90%]">
        {isSubmited ? (
          <div>
            <div className="font-zarid text-xl text-green-primary border border-green-primary px-4 rounded-md">
              <div className="flex items-center justify-beween gap-x-4  py-3 border-b border-green-primary">
                <div className="flex flex-col sm:flex-row w-full gap-x-2">
                  <h5 className="">Contact: </h5>
                  <input
                    className="bg-transparent w-full sm:w-[70%]"
                    type="text"
                    value={contactInput}
                    name=""
                    id=""
                    readOnly
                  />
                </div>
                <button
                  onClick={() => onChange()}
                  className="ml-auto transition-all ease-in duration-300 hover:underline"
                >
                  change
                </button>
              </div>
              <div className="flex items-center justify-between py-3">
                <div className="flex-1 flex gap-x-4 w-full">
                  <div className="flex flex-col sm:flex-row w-full gap-x-2">
                    <h5 className="fon">Ship to :</h5>

                    <input
                      className="bg-transparent  w-full sm:w-[75%]"
                      type="text"
                      value={`${country}, ${cityInput}, ${apartmentInput}, ${companyInput}, ${postalCodeInput} `}
                      name=""
                      id=""
                      readOnly
                    />
                  </div>
                </div>

                <button
                  onClick={() => onChange()}
                  className=" transition-all ease-in duration-300 hover:underline"
                >
                  change
                </button>
              </div>
            </div>

            <div>
              <h2 className="font-zarid text-2xl text-green-primary mt-8 mb-3">
                Payment method
              </h2>

              <div className="flex items-center justify-between py-3 px-4 border border-green-primary rounded-md font-zarid text-green-primary text-xl">
                <p>Cash on delivery</p>
                <p>Free fees</p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h2 className="text-2xl text-green-primary mb-3">
              Contact Information
            </h2>
            <div className="mb-6">
              <Input
                inputStyle={contactStyle}
                type={"email"}
                name={"contactInfo"}
                id={"contactInfo"}
                value={contactInput}
                placeholder={"Your Email"}
                onChange={contactChangeHandler}
                onBlur={contactBlurHandler}
                hasError={contactHasError}
                errorMessage={FormInputMessage.email}
              />
            </div>
            <h2 className="text-2xl text-green-primary mb-3">
              Shipping address
            </h2>
            <select
              className="bg-transparent border border-green-primary px-2 py-3 rounded-md min-w-full mb-6"
              value={country}
              onChange={handleCountryChange}
            >
              <option value="Lebanon">Lebanon</option>
              <option value="Kuwait">Kuwait</option>
            </select>
            <div className="flex flex-col md:flex-row items-center gap-x-4">
              <Input
                boxStyle={"w-full"}
                inputStyle={firstNameStyle}
                type={"text"}
                name={"firstName"}
                id={"firstName"}
                value={fristNameInput}
                placeholder={"First name"}
                onChange={firstNameChangeHandler}
                onBlur={firstNameBlurHandler}
                hasError={firstNameHasError}
                errorMessage={FormInputMessage.text}
              />
              <Input
                boxStyle={"w-full"}
                inputStyle={lastNameStyle}
                type={"text"}
                name={"lastName"}
                id={"lastName"}
                value={lastNameInput}
                placeholder={"Last name"}
                onChange={lastNameChangeHandler}
                onBlur={lastNameBlurHandler}
                hasError={lastNameHasError}
                errorMessage={FormInputMessage.text}
              />
            </div>
            <Input
              inputStyle={companyStyle}
              type={"text"}
              name={"company"}
              id={"company"}
              value={companyInput}
              placeholder="Company"
              onChange={companyChangeHandler}
              onBlur={companyBlurHandler}
              hasError={companyHasError}
              errorMessage={FormInputMessage.text}
            />
            <Input
              inputStyle={addressStyle}
              type={"text"}
              name={"address"}
              id={"address"}
              value={addressInput}
              placeholder={"Address"}
              onChange={addressChangeHandler}
              onBlur={addressBlurHandler}
              hasError={addressHasError}
              errorMessage={FormInputMessage.text}
            />{" "}
            <Input
              inputStyle={apartmentStyle}
              type={"text"}
              name={"apartment"}
              id={"apartment"}
              value={apartmentInput}
              placeholder="Apartment, suite, etc."
              onChange={apartmentChangeHandler}
              onBlur={apartmentBlurHandler}
              hasError={apartmentHasError}
              errorMessage={FormInputMessage.text}
            />
            <div className="flex flex-col md:flex-row items-center gap-x-4">
              <Input
                boxStyle={"w-full"}
                inputStyle={cityStyle}
                type={"text"}
                name={"city"}
                id={"city"}
                value={cityInput}
                placeholder={"City"}
                onChange={cityChangeHandler}
                onBlur={cityBlurHandler}
                hasError={cityHasError}
                errorMessage={FormInputMessage.text}
              />{" "}
              <Input
                boxStyle={"w-full"}
                inputStyle={postalCodeStyle}
                type={"number"}
                name={"postalCode"}
                id={"postalCode"}
                value={postalCodeInput}
                placeholder={"Postal code"}
                onChange={postalCodeChangeHandler}
                onBlur={postalCodeBlurHandler}
                hasError={postalCodeHasError}
                errorMessage={FormInputMessage.postalCode}
              />
            </div>
            <Input
              inputStyle={phoneNumberStyle}
              type={"number"}
              name={"phoneNumber"}
              id={"phoneNumber"}
              value={phoneNumberInput}
              placeholder={"Phone number"}
              onChange={phoneNumberChangeHandler}
              onBlur={phoneNumberBlurHandler}
              hasError={phoneNumberHasError}
              errorMessage={FormInputMessage.number}
            />
          </div>
        )}
        <span className="mt-8 flex items-center justify-end">
          {!isSubmited && (
            <button
              type="button"
              onClick={() => finalStepHandler()}
              className={`font-zarid text-green-primary text-2xl py-2 px-4 rounded-md   ${
                formISValid
                  ? `${formStyle.isValidBtn}`
                  : `${formStyle.notValidBtn}`
              }`}
            >
              Continue To Shipping
            </button>
          )}

          {isSubmited && (
            <button
              type="submit"
              className={`font-zarid text-green-primary text-2xl py-2 px-4 rounded-md   ${
                formISValid
                  ? `${formStyle.isValidBtn}`
                  : `${formStyle.notValidBtn}`
              }`}
            >
              Confirm Order
            </button>
          )}
        </span>
      </form>
    </div>
  );
};

export default PaymentForm;
