// UI
import Container from "../../components/container/Container";
// Styles
import styles from "../../style";
// data
import { sourcing } from "../../constant/About";
// image
import { sourcingImageOne, sourcingImaTwo } from "./about-images";

const Sourcing = () => {
  return (
    <section className={styles.spacingY}>
      <Container>
        <h1 className={`${styles.header} md:w-[75%] lg:w-[60%] `}>
          {sourcing.header}
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-6">
          <div className="h[100%]">
            <img
              className="w-full h-[350px] object-cover "
              src={sourcingImageOne}
              alt="koura lebanon"
            />
          </div>
          <div className="text-center lg:px-4">
            <h4
              className={`${styles.textXL} font-zarid capitalize mb-3 xl:mb-6`}
            >
              {sourcing.title}
            </h4>
            <p className={`${styles.textMD} font-zarid font-normal `}>
              {sourcing.text}
            </p>
          </div>
          <div className="h[100%]">
            <img
              className="w-full h-[350px] object-cover "
              src={sourcingImaTwo}
              alt="koura lebanon"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Sourcing;
