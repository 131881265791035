import ReactDOM from "react-dom";

const ContactButton = () => {
  return (
    <>
      {ReactDOM.createPortal(
        <a
          href="https://wa.me/96170339088"
          target="{_blank}"
          className="fixed right-[15px] bottom-[15px] z-[4] text-white text-3xl bg-green-wts rounded-full p-2 flex items-center justify-center"
        >
          <ion-icon name="logo-whatsapp"></ion-icon>
        </a>,
        document.getElementById("contact")
      )}
    </>
  );
};

export default ContactButton;
