import React from "react";

const MobileNavBtn = ({ blog, onShowMenu, isActive }) => {
  return (
    <button
      onClick={onShowMenu}
      className={`block lg:hidden text-3xl flex items-center justify-center ${
        blog ? "text-white" : "text-green-primary"
      } z-[4]`}
    >
      {!isActive && <ion-icon name="menu-outline"></ion-icon>}
      {isActive && <ion-icon name="close-outline"></ion-icon>}
    </button>
  );
};

export default MobileNavBtn;
