import { Link } from "react-router-dom";
// Images
import { logo, whiteLogo } from "../../../../assests/images/index";

const Logo = ({ blog }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className=" absolute top-[50%] -translate-y-1/2 left-[50%] -translate-x-1/2 lg:left-0 lg:translate-x-0 flex items-center justify-center z-[4]">
      <Link to={"/"} onClick={scrollToTop}>
        <img
          className={" logo object-contain z-[4] w-[7rem] lg:w-[10.8rem] pt-1"}
          src={blog ? whiteLogo : logo}
          alt="sama company logo"
        />
      </Link>
    </div>
  );
};

export default Logo;
