import { useState, useEffect } from "react";
// custom hook
import useGetData from "../../hooks/use-get-data";
// UI
import Container from "../../components/container/Container";
import ProdcutsHeader from "../../components/Products/ProdcutsHeader";
// components
import ProductsGrid from "./ProductsGrid";
import LoadingComponent from "./LoadingComponent";

// fetching data
import getProductContentContent from "../../services/fetchingData/fetchProductContent";

const ShopHome = () => {
  const [productsData, setProductsData] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const data = useGetData(getProductContentContent);
  useEffect(() => {
    if (!data) {
      setProductsData([]);
      setIsLoading(true);
    } else {
      setProductsData(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (productsData.length === 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [productsData]);

  return (
    <section>
      <Container>
        <ProdcutsHeader />
        {!isloading && <ProductsGrid products={productsData} />}
        {isloading && <LoadingComponent />}
      </Container>
    </section>
  );
};

export default ShopHome;
