import { Routes, Route } from "react-router-dom";

// Portals
import ContactButton from "./components/UI/ContactButton";
import Cart from "./components/Cart/Cart";

// components
import Header from "./layout/Header/Header";
import Footer from "./layout/Footer/Footer";

// Context
import ContextProvider from "./context/Context-Provider";

// Routes
import {
  Home,
  About,
  Faqs,
  Contact,
  Shop,
  DisplayProductsPage,
  CheckoutPage,
} from "./routes/index";

import {
  Blog,
  Education,
  Recipes,
  RecipesBlog,
  EducationBlog,
} from "./pages/Blog/index";

const App = () => {
  return (
    <ContextProvider>
      {/* Protals */}
      <ContactButton />
      <Cart />
      {/* App */}

      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path="about-us" element={<About />} />
        <Route path="blog" element={<Blog />}>
          <Route path="recipes" element={<Recipes />} />
          <Route path="recipes/recipesBlog" element={<RecipesBlog />} />
          <Route path="eduction" element={<Education />} />
          <Route path="eduction/educationBlog" element={<EducationBlog />} />
        </Route>
        <Route path="shop" element={<Shop />} />
        <Route path="shop/displayProducts" element={<DisplayProductsPage />} />
        <Route path="faqs" element={<Faqs />} />
        <Route path="contact" element={<Contact />} />
        <Route path="checkout" element={<CheckoutPage />} />
        {/* Redirect if the url is borken or not exist */}
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </ContextProvider>
  );
};

export default App;
