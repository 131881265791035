import React from "react";

const Promotion = ({ blog }) => {
  return (
    <div
      className={`relative z-[4] border-b ${
        blog ? "border-white" : "border-green-primary"
      }`}
    >
      <p
        className={`font-zarid text-xl font-medium text-center uppercase  ${
          blog ? "text-white " : "text-green-primary "
        } `}
      >
        TRUE TASTE OF LEBANON
      </p>
    </div>
  );
};

export default Promotion;
