// UI
import Container from "../../../components/container/Container";
// style
import styles from "../../../style";
// Components
import BlogCard from "./BlogCard";

const BlogBody = (props) => {
  return (
    <>
      <section className={`${styles.SMspacingY} bg-background-primary`}>
        <Container>
          <BlogCard data={props.data} to={props.to} />
        </Container>
      </section>
    </>
  );
};

export default BlogBody;
