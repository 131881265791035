import { createContext, useState, useEffect } from "react";

export const UserLocationContext = createContext();

export const GetUserLocationProvider = ({ children }) => {
  const [userLocation, setUserLocation] = useState("");
  useEffect(() => {
    const getLocation = async () => {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      setUserLocation(data.country_code);
    };
    getLocation();
  }, []);

  return (
    <UserLocationContext.Provider value={{ userLocation, setUserLocation }}>
      {children}
    </UserLocationContext.Provider>
  );
};
