import { useState, useEffect, useRef } from "react";

import "./GallerySlider.css";

import { slidesImageHigh, slidesImagesLow } from "./index";
const doubleSlideImageHigh = [...slidesImageHigh, ...slidesImageHigh];
const doubleSlidImgeLow = [...slidesImagesLow, ...slidesImagesLow];

const GallerySlider = () => {
  const [imageArray, setImageArray] = useState(doubleSlidImgeLow);
  const ref = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.6,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setImageArray(doubleSlideImageHigh);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return (
    <div ref={ref}>
      <div
        id="gallery-container"
        className="flex gap-x-4 overflow-hidden flex-nowrap"
      >
        {imageArray.map((img, i) => (
          <div key={i} className="image-slide">
            <img src={img} className="gallery-image" alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GallerySlider;
