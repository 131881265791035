export const footerLinks = [
  {
    title: "shop",
    links: [
      {
        id: 1,
        name: "Shop",
        link: "/shop",
      },
      {
        id: 2,
        name: "Fruity 100% Lebanese",
        link: "/shop",
      },
      {
        id: 3,
        name: "Bold 100% Lebanese",
        link: "/shop",
      },
    ],
  },
  {
    title: " Quick Links",
    links: [
      {
        id: 4,
        name: "Abouts Us",
        link: "/about-us",
      },
      {
        id: 5,
        name: "Contact us",
        link: "/contact",
      },
      {
        id: 6,
        name: "Recipes",
        link: "/blog/recipes",
      },
    ],
  },
  {
    title: "social",
    links: [
      {
        id: 7,
        social: "Facebook",
        go: "https://www.facebook.com/samapremium/",
      },
      {
        id: 8,
        social: "Instagram",
        go: "https://instagram.com/sama.premium?igshid=YmMyMTA2M2Y=",
      },
      {
        id: 9,
        social: "Linkedin",
        go: "https://www.linkedin.com/company/sama-premium/",
      },
      {
        id: 10,
        social: "TikTok",
        go: "https://www.tiktok.com/@sama.premium?_t=8XysIDw1Ng2&_r=1",
      },
    ],
  },
];
