import styles from "../../../style";
// UI
import Button from "../../../components/UI/Button/Button";
import Products from "../../../components/Products/Products";

const RecipeBody = (props) => {
  const { data } = props;
  return (
    <>
      <section className={`${props.className} ${styles.SMspacingY}`}>
        <div className="recipes-grid gap-y-14 md:gap-y-0">
          {/* ing */}
          <div className="flex flex-col gap-y-8 w-full lg:w-[90%] xl:w-[80%]  px-6 py-4 bg-white rounded h-max">
            <div className="flex items-center justify-between font-zarid text-xl w-full  ">
              <span className="flex items-center justify-center text-2xl ">
                <ion-icon name="flower-outline"></ion-icon>
              </span>
              <h1 className="font-siriRegular">INGREDIENTS</h1>
              <span className="flex items-center justify-center text-2xl ">
                <ion-icon name="flower-outline"></ion-icon>
              </span>
            </div>
            <div className="flex flex-col gap-y-4 font-zarid text-green-primary text-xl">
              {data.ingredients.map((ing) => (
                <span className="flex items-center gap-x-4">
                  <p>{ing.quantity}</p>
                  <p>{ing.item}</p>
                </span>
              ))}
            </div>
          </div>
          {/* inst */}
          <div>
            {/* header */}
            <div className="flex w-full flex-col gap-y-6 md:flex-row md:items-center md:justify-between md:gap-y-0  border-b border-dashed border-green-primary pb-4">
              <div className="flex-1 font-zarid text-green-primary">
                <h1 className="font-siriRegular text-5xl mb-3">Instructions</h1>
                <p className="text-2xl leading-none">
                  Active Time: {data.activeTime}
                </p>
                <p className="text-2xl leading-none">
                  Full Time: {data.fullTime}
                </p>
              </div>
              <div className="flex-1 flex flex-col gap-y-4">
                <span className="font-zarid text-2xl text-green-primary bg-white w-[50%] md:ml-auto text-center py-1 rounded uppercase">
                  Serves {data.serve}
                </span>
                <Button
                  to={"/shop"}
                  data={"Shop Now"}
                  className={"font-zarid text-2xl py-5 px-2 w-[100%]"}
                />
              </div>
            </div>
            {/* instru */}
            <div className="mt-20 flex flex-col gap-y-10">
              {data.instructions.map((ins) => (
                <span className="flex gap-x-8 md:gap-x-10 lg:gap-x-14 font-zarid text-green-primary">
                  <p className="text-3xl bg-accent h-[1rem] w-[1rem] flex items-center justify-center border border-green-primary rounded-full p-5">
                    {ins.stepNumber}
                  </p>
                  <p className="text-xl tracking-wider leading-snug w-[75%]">
                    {ins.description}
                  </p>
                </span>
              ))}

              <div>
                <img
                  src={data.imageTwo}
                  className="object-cover w-full h-[600px] rounded"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Products className={"pt-24 md:pt-44"} />
    </>
  );
};

export default RecipeBody;
