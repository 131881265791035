import "./ProcessCard.css";

const ProcessCard = (props) => {
  const classes =
    "relative process-box px-2 pt-4 text-center flex flex-col items-center justify-between gap-4 cursor-pointer " +
    props.className;
  return (
    <div className={classes}>
      <h4 className={`text-[1.6rem] leading-[2.2rem] font-zarid mb-5`}>
        {props.header}
      </h4>
      <h1 className="text-[3rem] leading-none font-siriRegular">
        {props.discription}
      </h1>
      <img
        className={"mb-4 olive-image w-[4.4rem] object-cover z-[2]"}
        src={props.oliveImage}
        alt="olive oil bottle"
      />
      <div className="h-[12rem] lg:h-[10rem]">
        <img
          className="bottle-image h-[100%]"
          src={props.bottleImage}
          alt="olive oil bottle"
        />
      </div>

      <span className="hidden md:block absolute w-full h-[1px] border border-dashed border-white top-[50%] -translate-y-/2 z-[0]"></span>
    </div>
  );
};

export default ProcessCard;
