import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
// styles
import styles from "../../style";
// UI
import Container from "../../components/container/Container";
// context
import { CartItemsContext } from "../../context/Cart-shop-context";
import placeHolderImage from "../../assests/images/Shop/bottlePlaceholer.png";

const DisplayProducts = () => {
  // context
  const cartCTX = useContext(CartItemsContext);

  // Get data converted from the product grid page
  const { data, userLocation } = useLocation().state ?? {};
  const { name, title, descriptions, variants, sku } = data;

  const { desc1, desc2, desc3, desc4 } = descriptions[0];
  const descArry = [desc1, desc2, desc3, desc4];

  // is loading state to place a placholder image until the image load
  const [isLoading, setIsLoading] = useState(true);
  const onLoadHandler = () => {
    setIsLoading(false);
  };
  // States for displaying the product details
  const [price, setPrice] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [image, setImage] = useState(variants[0].image);
  const [selectedVariant, setSelectedVariant] = useState(variants[0]);
  const [quantity, setQuantity] = useState(1);

  // Update the price and currency when the selected variant or quantity changes
  useEffect(() => {
    const { price_KD, price_USD } = selectedVariant;
    const priceString = userLocation === "KW" ? price_KD : price_USD;
    const currency = priceString.split(" ")[1];
    setBasePrice(+priceString.split(" ")[0]);
    const updatedPrice = (+priceString.split(" ")[0] * quantity).toFixed(2);
    setPrice(`${updatedPrice} ${currency}`);
  }, [selectedVariant, quantity, userLocation]);

  // Handle changes in the selected variant
  const handleVariantChange = (id) => {
    const newVariant = variants.find((product) => product.id === id);
    setSelectedVariant(newVariant);
    setImage(newVariant.image);
  };

  // Handle changes in the quantity
  const handleQuantityChange = (operator) => {
    if (operator === "PLUS") {
      setQuantity((quantity) => quantity + 1);
    } else if (quantity > 1) {
      setQuantity((quantity) => quantity - 1);
    }
  };

  // Get the price of the default variant based on the user's location
  const getDefaultPrice = () =>
    userLocation === "KW" ? variants[0].price_KD : variants[0].price_USD;

  // Set the initial price when the user's location changes
  useEffect(() => {
    setPrice(getDefaultPrice());
  }, [userLocation]);

  const addToCartHandler = () => {
    cartCTX.addItem({
      sku: sku,
      id: selectedVariant.id,
      name: name,
      basePrice: basePrice,
      price: price,
      size: selectedVariant.size,
      quantity: quantity,
      image: selectedVariant.image,
    });
  };

  // const imageSrc = isLoading ? placeHolderImage : image;

  return (
    <section className={`bg-background-primary`}>
      <Container>
        <Link
          className="group/text text-2xl text-green-primary border border-green-primary rounded-md flex items-center justify-center w-max py-1 px-1.5 transition ease-in duration-300 hover:bg-green-primary  hover:text-background-primary"
          to={"/shop"}
        >
          <ion-icon name="chevron-back-outline"></ion-icon>
          <p className="text-[18px] font-zarid leading-[1] mt-0.5 hidden group-hover/text:block ">
            Back to Shop
          </p>
        </Link>
        <div
          className={`${styles.SMspacingY} flex flex-col lg:flex-row gap-y-14`}
        >
          {/* image box */}
          <div
            className={
              "w-full md:w-[75%] lg:w-full mx-auto flex items-center justify-center"
            }
          >
            {/* <img
            src={imageSrc}
            alt=""
            onLoad={onLoadHandler}
            className={`w-[80%] mx-auto  transition ease-in duration-500  ${
              imageSrc === placeHolderImage
              ? "w-[20rem] h-[20rem] object-cover"
              : ""
            }`}
            /> */}

            {isLoading ? (
              <img
                src={placeHolderImage}
                alt=""
                onLoad={onLoadHandler}
                className={`mx-auto  w-[5rem] h-[5rem] transition ease-in duration-500  
                `}
              />
            ) : (
              <img
                src={image}
                alt=""
                onLoad={onLoadHandler}
                className={`w-[80%] mx-auto  transition ease-in duration-500 `}
              />
            )}
          </div>
          {/* end image box */}

          {/* info box */}
          <div
            className={
              "w-full md:w-[75%] lg:w-full md:text-center md:mx-auto lg:text-start  flex flex-col justify-center gap-y-4"
            }
          >
            {/* name */}
            <div>
              <h1
                className={
                  "font-siriRegular text-3xl  md:w-full ss:text-4xl text-green-primary leading-none ss:leading-none"
                }
              >
                {name}
              </h1>

              {title && (
                <h4 className="font-siriRegular italic text-xl mt-1 text-green-primaryLight">
                  {" "}
                  {title}{" "}
                </h4>
              )}
            </div>
            {/* description */}
            <div className="flex flex-col gap-y-1">
              {descArry.length
                ? descArry.map((text, i) => (
                    <p key={i} className={"text-green-primary"}>
                      {text}
                    </p>
                  ))
                : ""}
            </div>
            {/* price */}
            <p className="text-green-primary text-4xl font-siriRegular">
              {price}
            </p>
            {/* sizes */}
            <div className="flex items-center w-max md:w-auto md:justify-center lg:justify-start lg:w-max gap-x-4 ">
              {variants.map((size) => (
                <button
                  key={size.id}
                  onClick={() => handleVariantChange(size.id)}
                  className={`border border-green-primary px-4 py-1.5 font-siriRegular text-green-primary flex items-center justify-center transition ease-in duration-300 hover:bg-green-primaryLighter hover:text-background-primary  ${
                    selectedVariant.id === size.id
                      ? "bg-green-primary text-background-primary"
                      : ""
                  }`}
                >
                  <span> {size.size}</span>
                </button>
              ))}
            </div>
            {/* quantitu function*/}
            <div className="flex flex-col sm:flex-row gap-y-4 sm:gap-y-0 items-center gap-x-4">
              <div
                className={
                  "flex items-center justify-between gap-x-8 font-zarid  text-green-primary border border-green-primaryLighter w-full px-4 rounded  "
                }
              >
                <button
                  onClick={() => handleQuantityChange("MINUS")}
                  className={"text-5xl  w-[2rem]"}
                >
                  -
                </button>
                <input
                  type="number"
                  value={quantity}
                  readOnly
                  className="font-siriRegular bg-transparent text-green-primary text-3xl select:border-0 w-[3rem] text-center leading-none"
                />
                <button
                  onClick={() => handleQuantityChange("PLUS")}
                  className={"text-5xl w-[2rem]"}
                >
                  +
                </button>
              </div>
              {/* purchasing  */}
              {/* <div className="flex items-center w-full gap-x-3 lg:gap-x-6"> */}
              <button
                onClick={() => addToCartHandler()}
                className={
                  "w-full font-zarid text-green-primary text-2xl border border-green-primaryLighter py-2 uppercase transition ease-in duration-300 hover:hover:bg-green-primary hover:text-background-primary rounded"
                }
              >
                Add To Cart
              </button>
            </div>
            {/* <button
                className={
                  "w-full font-zarid text-background-primary text-2xl border border-green-primary bg-green-primary py-2 uppercase transition ease-in duration-300 hover:bg-brand hover:text-green-primary rounded"
                }
              >
                Buy it now
              </button> */}
            {/* </div> */}
          </div>
          {/* end info box */}
        </div>
        {/* <Comparison /> */}
      </Container>
    </section>
  );
};

export default DisplayProducts;
