import Products from "../../../components/Products/Products";

const EducationBody = (props) => {
  const { data } = props;
  return (
    <>
      <div className="flex flex-col gap-y-16 w-[90%] md:w-[75%] lg:w-[50%] md:mx-auto">
        {data.content.map((cnt) => (
          <span className="flex flex-col gap-y-4 text-green-primary font-zarid text-2xl leading-snug">
            <p className="font-siriRegular text-3xl">{cnt.header}</p>
            <p className="text-green-primaryLighter">{cnt.text}</p>
          </span>
        ))}
      </div>

      <Products className={"pt-24 md:pt-44"} />
    </>
  );
};

export default EducationBody;
