import styles from "../../../style";
import Input from "../../../components/Input/Input";
import useInput from "../../../hooks/user-input";

const FooterContent = () => {
  const {
    value: emailInput,
    isValid: emailISValid,
    HasError: emailHasError,

    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlueHandler,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });

  const formSubmitionHandler = async (event) => {
    event.preventDefault();

    const formState = {
      emailInput,
    };

    if (!emailISValid) return;

    try {
      const response = await fetch(
        "https://public.herotofu.com/v1/3f5b1570-c413-11ed-b4ac-77574673b9e5",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formState),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      alert(error.message);
    }

    emailReset();
  };
  return (
    <div
      className={`flex-1 flex justify-between flex-col border-b lg:border-b-0 lg:border-r  ${styles.borderDashed} py-10 lg:py-0`}
    >
      <h4 className={`font-zarid ${styles.textXL} font-normal mb-6`}>
        Join the Sama Collective Today!
      </h4>
      <p className={`font-zaridLight font-light ${styles.textBaseLight} mb-12`}>
        <span className="inlinle md:block">
          Do you enjoy cooking? Do you love olive oil? {""}
        </span>
        <span className="inlinle md:block md:max-w-[75%]">
          We are trying to grow our sama family as we educate the globe about
          the freshest olive oil! {""}
        </span>
        <span className="inlinle md:block">
          Send us an email if you're interested!
        </span>
      </p>
      <form
        onSubmit={formSubmitionHandler}
        className={`${styles.border} flex items-center justify-between px-2 py-3 gap-x-2  w-[90%] text-lg`}
      >
        <input
          className={`bg-transparent w-full `}
          id={"submit-email"}
          type={"submit-email"}
          name={"submit-email"}
          placeholder={"Your Email"}
          value={emailInput}
          onChange={emailChangeHandler}
          onBlur={emailBlueHandler}
        />

        <button
          className="flex items-center gap-x-3"
          type="submit"
          href="/"
          id="form-btn"
        >
          Submit
          <span className={`${styles.borderIcon}`}>
            <ion-icon
              className="footer-icon"
              name="arrow-forward-outline"
            ></ion-icon>
          </span>
        </button>
      </form>
      <p
        className={`text-[14px] leading-none text-red-600 mt-1.5 mb-2.5 transition ease-in duration-200 ${
          emailHasError ? "opacity-1 " : "opacity-0"
        }`}
      >
        Please enter a valid email address (e.g. john@example.com
      </p>
    </div>
  );
};

export default FooterContent;
