import styles from "../../../../style";
import "./GallerySlider.css";
import GallerySlider from "./GallerySlider";

const GalleryComponents = () => {
  return (
    <div
      className={`overflow-hidden border-t lg:border-t-0 ${styles.borderDashed} pt-5`}
    >
      <div
        className={`flex flex-col lg:flex-row items-center justify-between mb-10 font-zarid ${styles.textMD} font-normal`}
      >
        <p className="text-center lg:text-left pb-4 lg:pb-0 ">
          Share your Sama products posts on social media,
          <span className="inline lg:block">
            and Earn a commission by receiving a custom special offer
          </span>
        </p>
        <a
          href={"https://www.instagram.com/sama.premium/?hl=en"}
          target="_blank"
          rel="noreferrer"
        >
          <p
            className={`flex items-center gap-x-3 pb-2 border-b ${styles.borderDashed} `}
          >
            Follow us
            <span className={`${styles.borderIcon}`}>
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </span>
          </p>
        </a>
      </div>

      <div className={`border-y ${styles.borderDashed} `}>
        <GallerySlider />
      </div>
    </div>
  );
};

export default GalleryComponents;
