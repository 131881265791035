// UI Components
import Container from "../components/container/Container";
import BigLogo from "../components/UI/BigLogo";
import Products from "../components/Products/Products";
// Page components
import { Hero, Alert, FunFact, Comparison } from "../pages/Home/index";

const Home = () => {
  return (
    <main className="overflow-x-hidden bg-background-primary">
      <Hero />
      <Alert />
      <Container>
        <BigLogo className={"mb-12 lg:mt-0 "} />
      </Container>
      <Products />
      <FunFact className={"pt-20 md:pt-32"} />
      <Comparison />
    </main>
  );
};

export default Home;
