export const FaqHeader = {
  header: "olive Oil FAQ",
  text: "All the information you require regarding nature's tasty love juice.",
};

export const FaqContent = [
  {
    question: "What place produces the greatest olive oil?",
    answers: [
      {
        answer:
          "Regardless where it comes from, the freshest olive oil is always the best",
      },
    ],
  },
  {
    question: "How would you pick the finest ones?",
    answers: [
      { answer: "Discover when the olives were picked." },
      {
        answer:
          "You can count on the olives to be tasty if the label indicates when they were collected. You should ideally buy olive oil within a year of the harvest date, which is referred to as the harvest date.",
      },
      {
        answer:
          "That's because authentic extra virgin olive oil is simply cold-pressed juice that has been mixing procedure—to separate it from the remainder of the olive. Extra virgin oil begins to change as soon as it leaves the tree because it is a raw material.",
      },
    ],
  },
  {
    question:
      "What about fake extra virgin oil? Why would anyone lie about that?",
    answers: [
      {
        answer:
          "100% extra virgin oil is sometimes pricey; the ancient Greeks referred to the premium stuff as liquid gold. Due to its high value, olive oil fraud has been going on for thousands of years, is common across the business, and is incredibly profitable to fraudsters that can make a 700% return just by lying to you about your oil.",
      },
      {
        answer:
          "You can trust that SAMA olive oil is not only excellent but also precisely what we claim it to be since we are open about where it comes from.",
      },
    ],
  },
  {
    question:
      "What about Italy? Is it true that most premium olive oil come from Italy?",
    answers: [
      { answer: "Not all olive oil originates from Italy." },
      {
        answer:
          "Even the majority of 'Italian' olive oil isn't produced there. It’s all marketing.",
      },
      {
        answer:
          "Surprisingly, just 15% of the world's olive oil is produced in Italy. A highly inconvenient bacteria known as Xylella fastidiosa has recently attacked their olive industry. Global warming also affected their olive estate, limiting the Italian supply and driving up the price of their oil. It's difficult to find 100% extra virgin olive oil from Italy",
      },
    ],
  },
  {
    question: "What is the type of our olive tree?",
    answers: [
      {
        answer:
          "The olive cultivar is the Souri variety, considered to be among the earliest to be grown by mankind. Known in the region under many names, including Nabala and Roman, Souri is common in Lebanon, it is considered highly resilient to drought and it is highly praised for its yields and the unique fruity flavors of its oil.",
      },
      {
        answer:
          "Our trees are built for the long run, and our farms are rocking trees with an average age between 20-200 years old!",
      },
    ],
  },
  {
    question:
      "What does good olive oil taste like? How do you know if it’s high quality?",
    answers: [
      {
        answer:
          "Olive oil can have a bitter, peppery, fruity, buttery, nutty, or grassy flavor. Sometimes it combines all of those elements.",
      },
      {
        answer:
          "Every olive type has a unique flavor, and the same is true of olive oils. But it must always be layered and complex. Your dish won't taste exciting if your olive oil doesn't taste exciting on its own.",
      },
    ],
  },
  {
    question: "Is olive oil really as healthful as they claim?",
    answers: [
      { answer: "Yes! As they say, the hype is real." },
      {
        answer:
          "Extra virgin olive oil is the healthiest fat worth selling (Technically, extra virgin avocado oil is somewhat better for you, but fresh avocados are simply too delicious)",
      },
      {
        answer:
          "Monounsaturated fats like oleic acid and antioxidants like vitamins E and K are rich in olive oil. All of these are excellent for lowering inflammation. Olive oil use lowers the chance of developing heart disease, Alzheimer's, type 2 diabetes, and preliminary study indicates it may even prevent cancer. Even rheumatoid arthritis has been treated by some doctors using olive oil.",
      },
    ],
  },
];
