import Container from "../../../components/container/Container";
import HeroVideo from "./HeroComponents/HeroVideo";
import HeroContent from "./HeroComponents/HeroContent";

import "./Hero.css";

const Hero = () => {
  return (
    <section className="hero-height ">
      <HeroVideo />
      <Container>
        <HeroContent />
      </Container>
    </section>
  );
};

export default Hero;
