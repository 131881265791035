import styles from "../style";
import { ShopHome } from "../pages/Shop/index";

const Shop = () => {
  return (
    <main className={`bg-background-primary ${styles.SMspacingY}`}>
      <ShopHome />
    </main>
  );
};

export default Shop;
