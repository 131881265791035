import { useContext } from "react";
import {
  CartShowContext,
  CartItemsContext,
} from "../../../context/Cart-shop-context";

const AddToCartButton = ({ blog }) => {
  const { setCartOpen } = useContext(CartShowContext);
  const { items } = useContext(CartItemsContext);

  const handleCLick = () => {
    setCartOpen(true);
  };

  // const numberOfCartItems = items.reduce(
  //   (curNumber, item) => curNumber + item.amount,
  //   0
  // );
  return (
    <button onClick={handleCLick} className="flex items-center justify-center">
      <p
        className={`text-2xl leading-none pb-2   ${
          blog ? "text-white" : "text-green-primary"
        }`}
      >
        [{items.length}]
      </p>
    </button>
  );
};

export default AddToCartButton;
