import { Default_HOME_Content } from "../defaultData/defaultHomePageContent";

const HERO_API = "https://api.samapremium.co/api/v1/hero";

async function FetchingHomePageContent() {
  try {
    // const response = await fetch(`${HERO_API}`);
    // const data = await response.json();
    const response = "";
    const data = "";
    // if (!response.ok) {
    //   throw new Error(`Failed to fetch home page content: ${response.status}`);
    // }
    return data;
  } catch (error) {
    // throw new Error(`Failed to fetch home page content: ${error.message}`);
  }
}

export default async function getHomePageContent() {
  try {
    // const homePageData = await FetchingHomePageContent();
    // const { hero, alert, funfact } = homePageData.landingPage;
    // return { hero, alert, funfact };
  } catch (error) {
    console.log(error);
    return {
      hero: [
        {
          HeroHeader: Default_HOME_Content.Header.header,
          HeroButton: Default_HOME_Content.Header.button,
        },
      ],

      alert: [
        {
          alertText1: Default_HOME_Content.Alert.textOne,
          alertText2: Default_HOME_Content.Alert.textTwo,
        },
      ],

      funfact: [
        {
          text1: Default_HOME_Content.FunFact.text1,
          text2: Default_HOME_Content.FunFact.text2,
          text3: Default_HOME_Content.FunFact.text3,
          text4: Default_HOME_Content.FunFact.text4,
        },
      ],
    };
  }
}
