import { useState } from "react";
// STyle
import { productStyle } from "../../style";

const ProdcutsHeader = (props) => {
  const [freshActive, setFreshActive] = useState(false);
  const [blendedActive, setBlendedActive] = useState(false);
  const [evooActive, setEvooActive] = useState(false);

  return (
    <div className="text-center">
      <h1 className="inline md:block text-[2.4rem] sm:text-[3rem] md:text-[3.4rem] lg:text-[3.6rem] text-green-primary text-center font-siriRegular leading-none">
        Olive oil the way it should be:
      </h1>
      <span className={productStyle.productHeader}>
        {/* fresh */}
        <span className={productStyle.box}>
          <span
            role={"button"}
            onClick={() => setFreshActive(() => true)}
            className={`${productStyle.productBtn}`}
          >
            Always Fresh,
            <span
              role={"button"}
              className={`${productStyle.productDot}`}
            ></span>
          </span>
          <span
            className={`${productStyle.productContent} ${
              freshActive
                ? productStyle.productContentActive
                : productStyle.productContentDeactive
            }`}
          >
            <button
              onClick={() => setFreshActive(() => false)}
              className="lg:hidden text-4xl"
            >
              [close]
            </button>
            <p>
              the majority of the olive oil you find on shelves was older than a
              year. SAMA is collected during the same season as pressing,
              bottling, and shipping.
            </p>
          </span>
        </span>
        {/* Blended */}
        <span className={productStyle.box}>
          <span
            role={"button"}
            onClick={() => setBlendedActive(() => true)}
            className={`${productStyle.productBtn}`}
          >
            Never Blended,
            <span
              role={"button"}
              className={`${productStyle.productDot}`}
            ></span>
          </span>
          <span
            className={`${productStyle.productContent} ${
              blendedActive
                ? productStyle.productContentActive
                : productStyle.productContentDeactive
            }`}
          >
            <button
              onClick={() => setBlendedActive(() => false)}
              className="lg:hidden text-4xl"
            >
              [close]
            </button>
            <p>
              Major olive oil brands save money by mixing old, low-quality oils,
              some of which are olive-derived, from around the world while
              adding a country name to the label. That's shady! SAMA always
              originates from one country & never blended.
            </p>
          </span>
        </span>
        {/* Evoo */}
        <span className={productStyle.box}>
          <span
            role={"button"}
            onClick={() => setEvooActive(() => true)}
            className={`${productStyle.productBtn}`}
          >
            (real) EVOO
            <span
              role={"button"}
              className={`${productStyle.productDot}`}
            ></span>
          </span>
          <span
            className={`${productStyle.productContent} ${
              evooActive
                ? productStyle.productContentActive
                : productStyle.productContentDeactive
            }`}
          >
            <button
              onClick={() => setEvooActive(() => false)}
              className="lg:hidden text-4xl"
            >
              [close]
            </button>
            <p>
              Early olive harvesting and pressing ensure that "100%" extra
              virgin oil is produced, keeping the oil flavorful, tasty, and rich
              in polyphenols. SAMA is and always will be the real deal, unlike
              the majority of "extra virgin" bottles.
            </p>
          </span>
        </span>
      </span>
    </div>
  );
};

export default ProdcutsHeader;
