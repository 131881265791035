import { Default_FAQ_Content } from "../defaultData/defaultFaqContent";

const FAQ_API = "https://api.samapremium.co/api/v1/faq";

async function FetchingFaqPageContent() {
  try {
    const response = await fetch(`${FAQ_API}`);
    const data = await response.json();
    if (!response.ok) {
      throw new Error(`Failed to fetch home page content: ${response.status}`);
    }
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch home page content: ${error.message}`);
  }
}

export default async function getFaqPageContent() {
  try {
    const FaqPageData = await FetchingFaqPageContent();
    const { faqHero, faqContent } = FaqPageData.faqsPage;
    return { faqHero, faqContent };
  } catch (error) {
    console.log(error);
    return {
      faqHero: {
        faqHeader: Default_FAQ_Content.faqHero.faqHeader,
        faqHeroImage: Default_FAQ_Content.faqHero.faqHeroImage,
        faqText: Default_FAQ_Content.faqHero.faqText,
      },

      faqContent: [
        {
          faqQuestion: Default_FAQ_Content.faqContent,
          faqAnswer: Default_FAQ_Content.faqContent.faqAnswer,
        },
        {
          faqQuestion: "",
          faqAnswer: "",
        },
        {
          faqQuestion: "",
          faqAnswer: "",
        },
      ],
    };
  }
}
