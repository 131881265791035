import { Default_ABOUT_Content } from "./fetchAboutPageContent";

const ABOUT_API = "https://api.samapremium.co/api/v1/meetsourceparams";

async function FetchingAboutPageContent() {
  try {
    const response = await fetch(`${ABOUT_API}`);
    const data = await response.json();
    if (!response.ok) {
      throw new Error(`Failed to fetch home page content: ${response.status}`);
    }
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch home page content: ${error.message}`);
  }
}

export default async function getAboutPageContent() {
  try {
    const AbutPageData = await FetchingAboutPageContent();
    const { meetSama, process, processSteps, sourcing, sourcingImages, team } =
      AbutPageData.aboutPage;
    return {};
  } catch (error) {
    console.log(error);
    return {};
  }
}
