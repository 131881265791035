import { useContext, useState } from "react";
import { CartItemsContext } from "../../../context/Cart-shop-context.js";
import CartItem from "../../../components/Cart/CartItem.jsx";
import Button from "../../../components/UI/Button/Button.jsx";

const CartCheckout = () => {
  const cartCTX = useContext(CartItemsContext);
  const { items, addItem, removeItem, totalAmount } = cartCTX;
  const [isCollapsed, setIsCollapsed] = useState(false);
  let notItems = items.length === 0;
  const handleIncreaseQuantity = (item) => {
    const updatedItem = {
      ...item,
      quantity: +1,
    };
    addItem(updatedItem);
  };

  const handleDeacreseQunatity = (item) => {
    if (item.quantity < 2) return;
    const updatedItem = {
      ...item,
      quantity: -1,
    };
    addItem(updatedItem);
  };

  const handleRemove = (item) => {
    removeItem(item);
  };
  const handleToggleCollapse = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  return (
    <section>
      <div className="sm:px-6 flex flex-col gap-y-6 h-full">
        {notItems ? (
          <p className="text-green-primary font-siriRegular py-4 border-b border-dashed border-green-primary text-center">
            No Items
          </p>
        ) : (
          <button
            className="text-green-primary font-siriRegular pb-4 border-b border-dashed border-green-primary "
            onClick={handleToggleCollapse}
          >
            <p>
              {" "}
              See Your Products <span>[{items.length}]</span>
            </p>
          </button>
        )}
        <div className="w-full sm:w-[75%] sm:mx-auto">
          {isCollapsed &&
            !notItems &&
            items.map((item) => (
              <CartItem
                key={item.id}
                item={item}
                handleRemove={handleRemove}
                handleIncreaseQuantity={handleIncreaseQuantity}
                handleDeacreseQunatity={handleDeacreseQunatity}
              />
            ))}

          {notItems ? (
            <Button
              className={
                "font-zarid text-[24px] py-5 px-2 my-4 w-full  ss:mx-auto ss:w-[70%]"
              }
              to={"/shop"}
              data={"Return and fill up your cart"}
            ></Button>
          ) : (
            <div className="flex items-center justify-between my-6 text-2xl text-green-primary font-siriRegular ">
              {" "}
              <p>SubTotal</p>
              <p>{totalAmount}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CartCheckout;
