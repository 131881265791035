// UI
import Container from "../../components/container/Container";
// image
import { promisImage } from "./about-images";
// Styles
import styles from "../../style";

const OurPromise = () => {
  return (
    <section className={styles.spacingY}>
      <Container>
        <img
          src={promisImage}
          alt=""
          className="object-cover w-[100%] lg:w-[80%] lg:mx-auto h-[100%]"
        />
      </Container>
    </section>
  );
};

export default OurPromise;
