// custom hook
import useGetData from "../../hooks/use-get-data";
// UI
import Container from "../../components/container/Container";
// styles
import styles from "../../style";
// images
import { faqImage } from "../../assests/images/index";
// Data
import { FaqHeader } from "../../constant/Faqs";
import getFaqPageContent from "../../services/fetchingData/fetchingFaqContent";

const FaqsHero = () => {
  const data = useGetData(getFaqPageContent);
  return (
    <section className="pt-7">
      <Container>
        <div className="flex flex-col gap-y-10 md:gap-y-0 md:flex-row ">
          <div className="flex-1 flex items-center justify-center ">
            <img
              src={faqImage}
              alt="sama olive oil"
              className="h-[600px] w-full object-cover"
            />
          </div>
          <div className="flex-1 flex flex-col gap-y-2 md:items-center md:justify-center ">
            <h1 className="font-siriRegular text-5xl text-green-primary capitalize">
              {FaqHeader.header}
            </h1>
            <p className={`${styles.textBase} font-zarid md:text-center`}>
              {FaqHeader.text}
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FaqsHero;
