import { useLocation } from "react-router-dom";

// UI
import Container from "../../../components/container/Container";

// components
import BlogHero from "../Blog components/BlogHero";
import EducationBody from "./EducationBody";

const EducationBlog = () => {
  const location = useLocation();
  const data = location.state;

  return (
    <main className="bg-background-primary">
      <BlogHero data={data} />
      <Container>
        {/* negative top margin, because in the hero section we have a negative top translate */}
        <EducationBody className="-mt-[6.5rem]" data={data} />
      </Container>
    </main>
  );
};

export default EducationBlog;
