// UI
import Container from "../../components/container/Container";
// styles
import styles from "../../style";

import { FaqContent } from "../../constant/Faqs";

const FaqsBody = () => {
  return (
    <section className={styles.spacingY}>
      <Container>
        {FaqContent.map((item) => (
          <ul className="flex flex-col pb-8 md:w-[90%]">
            <li className="text-[2.3rem] md:text-[2.5rem] leading-none mb-2   font-zarid text-green-primary ">
              {item.question}
            </li>
            {item.answers.map((answer) => (
              <li className="text-[1.3rem] font-zarid text-green-primary leading-tight ">
                {answer.answer}
              </li>
            ))}
          </ul>
        ))}
      </Container>
    </section>
  );
};

export default FaqsBody;
