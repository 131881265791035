// Components
import BlogMainHero from "./Blog components/BlogMainHero";
import BlogBody from "./Blog components/BlogBody";
// General Images
import { recipesHero } from "./blog-image";
// Data
import { recipesBlog } from "../../constant/Recipes/RecipesBlogs";

const Recipes = () => {
  return (
    <>
      <div>
        <BlogMainHero
          tag={"recipes"}
          header={"Recipes"}
          text={"All the taste of olive oil comes in the food."}
          image={recipesHero}
        />
        <BlogBody data={recipesBlog} to={"/blog/recipes/recipesBlog"} />
      </div>
    </>
  );
};

export default Recipes;
