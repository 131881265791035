import { Link } from "react-router-dom";

const BlogCard = (props) => {
  const { data } = props;
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-14 md:gap-y-26 md:gap-x-4 ">
      {data.map((data) => (
        <Link
          to={props.to}
          state={data}
          className="group/card box flex-1 flex flex-col items-center gap-y-5 "
          key={data.name}
          onClick={scrollToTop}
        >
          <div className="overflow-hidden">
            <img
              className="w-full h-[32rem] md:h-[28rem] md:w-[28rem] lg:h-[20rem] lg :w-[20rem] object-cover rounded-lg transition ease-in duration-300 group-hover/card:scale-[1.05]"
              src={data.image}
              alt="sama olive oil recipes "
            />
          </div>
          <span className="border border-green-primary bg-white px-5 py-1 capitalize text-2xl text-green-primary font-zarid flex items-center justify-center ">
            <p className="p-0 m-0"> {data.tag}</p>
          </span>
          <h1 className="font-siriRegular text-2xl sm:text-[1.6rem] text-green-primary text-center capitalize">
            {data.name}
          </h1>
        </Link>
      ))}
    </div>
  );
};

export default BlogCard;
