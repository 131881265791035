import { useState, useEffect } from "react";
// custom hook
import useGetData from "../../hooks/use-get-data";
// UI Components
import Container from "../../components/container/Container";
// data
import getHomePageContent from "../../services/fetchingData/fetchHomePageContent";
import { Default_HOME_Content } from "../../services/defaultData/defaultHomePageContent";

const Alert = () => {
  const [alerData, setAlertData] = useState("");
  const data = useGetData(getHomePageContent);

  useEffect(() => {
    if (!data) {
      setAlertData(Default_HOME_Content.Alert);
    }

    if (data) {
      setAlertData(data.alert);
    }
  }, []);

  const { alertText1, alertText2 } = alerData;
  const textOne = alertText1;
  const textTwo = alertText2;

  return (
    <div className="hidden lg:block mb-6 xl:mb-10 xl:mt-2 ">
      <Container>
        <div className=" bg-white px-6 py-3 flex items-center justify-between font-zaridRegular text-xl">
          <span className="flex align-center justify-center">
            <ion-icon name="flower-outline"></ion-icon>
          </span>
          <span className="flex items-center gap-x-4 ">
            <h1>{textOne}</h1>
            <span className="flex align-center justify-center">
              <ion-icon name="ellipsis-vertical-outline"></ion-icon>
            </span>
            <h1>{textTwo}</h1>
          </span>
          <span className="flex align-center justify-center">
            <ion-icon name="flower-outline"></ion-icon>
          </span>
        </div>
      </Container>
    </div>
  );
};

export default Alert;
