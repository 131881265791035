import { useLocation } from "react-router-dom";
// UI
import Container from "../../components/container/Container";
import BigLogo from "../../components/UI/BigLogo";
// Components
import GalleryComponents from "./FooterComponents/Gallery/GalleryComponents";
import FooterSection from "./FooterComponents/FooterSection";
// Styles
import styles from "../../style";

const Footer = () => {
  const HomeLocation = useLocation().pathname === "/";
  return (
    <footer className={`${styles.spacingY} bg-background-primary `}>
      <Container>
        <GalleryComponents />
        <FooterSection />
        <BigLogo
          className={
            HomeLocation
              ? "hidden"
              : "border-dashed border-b border-green-primary"
          }
        />
      </Container>
    </footer>
  );
};

export default Footer;
