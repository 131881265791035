const formStyle = {
  input:
    "bg-transparent border border-green-primary px-2 py-3 rounded-md min-w-full",

  errorInput: "border-red-600",

  errorText:
    "text-[14px] leading-none text-red-600 mt-1.5 mb-2.5 transition ease-in duration-200",

  isValidBtn: "bg-brand",
  notValidBtn: "bg-green-primary text-white cursor-not-allowed",
};

export default formStyle;
