const Product_Content_API = "https://api.samapremium.co/api/v1/products";

async function FetchingProductContenttContent() {
  try {
    const response = await fetch(`${Product_Content_API}`);
    const data = await response.json();
    if (!response.ok) {
      throw new Error(`Failed to fetch home page content: ${response.status}`);
    }
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch home page content: ${error.message}`);
  }
}

export default async function getProductContentContent() {
  try {
    const ProductContentData = await FetchingProductContenttContent();
    const data = ProductContentData.data.data;
    return { data };
  } catch (error) {
    console.log(error);
    return {
      data: [],
    };
  }
}
