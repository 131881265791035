import slide1 from "../../../../assests/images/gallery/gallery-1.jpg";
import slide2 from "../../../../assests/images/gallery/gallery-2.jpg";
import slide3 from "../../../../assests/images/gallery/gallery-4.jpg";
import slide4 from "../../../../assests/images/gallery/gallery-5.jpg";
import slide5 from "../../../../assests/images/gallery/gallery-6.jpg";
import slide6 from "../../../../assests/images/gallery/gallery-7.jpg";

import { placeHolder } from "../../../../assests/images";

export const slidesImageHigh = [slide1, slide2, slide3, slide4, slide5, slide6];

export const slidesImagesLow = [
  placeHolder,
  placeHolder,
  placeHolder,
  placeHolder,
  placeHolder,
  placeHolder,
];
