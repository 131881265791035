import { Link } from "react-router-dom";

const DesktopNavBar = ({ blog }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <nav className="hidden lg:block header-nav  mr-6 ">
      <ul
        className={`flex items-center font-zarid gap-6 text-[1.5rem] leading-none uppercase ${
          blog ? "text-white" : "text-green-primary"
        }`}
      >
        <li>
          <Link onClick={scrollToTop} to={"/"}>
            Home
          </Link>
        </li>

        <li>
          <Link onClick={scrollToTop} to={"about-us"}>
            About Us
          </Link>
        </li>
        <li>
          <Link onClick={scrollToTop} to={"blog/recipes"}>
            Blog
          </Link>
        </li>
        <li>
          <Link onClick={scrollToTop} to={"shop"}>
            Shop
          </Link>
        </li>
        <li>
          <Link onClick={scrollToTop} to={"faqs"}>
            FAQs
          </Link>
        </li>
        <li>
          <Link onClick={scrollToTop} to={"contact"}>
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default DesktopNavBar;
