import FunFact from "../../components/FunFact/FunFact";

const LoadingComponent = () => {
  return (
    <div>
      <div className="flex justify-center items-center mt-8">
        <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.418 3.582 8 8 8v-2.009a5.985 5.985 0 01-2-.33V17z"
          ></path>
        </svg>
        <span>Loading...</span>
      </div>

      <div className="mt-10">
        <h2 className="text-center font-zarid text-3xl text-green-primary ">
          While we wait for the products to load, why not learn a fun fact about
          olive oil?
        </h2>

        <FunFact className={"mt-14"} />
      </div>
    </div>
  );
};

export default LoadingComponent;
