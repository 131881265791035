// eduction
import filter from "../../assests/images/Education/filter.jpg";
import pressed from "../../assests/images/Education/pressed.jpg";
import cook from "../../assests/images/Education/howCook.jpg";
import store from "../../assests/images/Education/store.jpg";

const FriendsSustainable = {
  name: "How to Store Extra Virgin Olive Oil",
  tag: "education",
  description:
    "Extra virgin olive oil should be stored in a cool, dark, and dry place away from heat, light, and moisture.",
  image: store,

  content: [
    {
      header: "How Does Heat Affect Extra Virgin Olive Oil?",
      text: "Heating and reheating extra virgin olive oil degrades the product significantly. Stop storing olive oil near your stove, oven, microwave, toaster oven, and any other heat-producing appliance. Heating and reheating extra virgin olive oil repeatedly unites the product, which is a procedure used to manufacture commercially refined olive oil. What we mean is that it degrades the oil and is equivalent to turning it into a commercial product... or pouring it down the drain. This does not mean that you should stop using EVOO in your cooking. Cooking with olive oil is excellent, but would you eat your steak or vegetables three times in a row?",
    },
    {
      header: "How Does Light Affect Extra Virgin Olive Oil?",
      text: "Light accelerates the degradation of extra virgin olive oil. Do not keep your EVOO on the tabletop or in other places with direct light, whether natural or artificial. Always keep olive oil in a dark spot away from any sources of light. This is one of the reasons it's critical to buy extra virgin olive oil in a dark glass or container.",
    },
    {
      header: "How Air Affects Extra Virgin Olive Oil?",
      text: "When extra virgin olive oil comes into touch with air, it begins to oxidize. To enjoy optimal taste characteristics, open bottles of olive oil should be consumed within 45 days. When olive oil begins to lose its zing. The flavors do not vanish, but they do begin to fade and become bland. You may notice that the oil no longer has the same punch it did when you first opened the bottle. Check that your oil bottles/tins are correctly closed after each usage.",
    },
    {
      header: "How Time Damages Extra Virgin Olive Oil",
      text: "Time is the most killer of EVOO. Olive oil should be consumed within 18 months of the bottle date or within two years of harvest. While olive oil never technically spoils, it does get rancid. Rancid oil smells like crayons or plastic and is unpleasant to eat. It may have an oily taste and fall extremely flat. Smelling an oil can tell you if it is rancid. ",
    },
    {
      header: "Where to Store Extra Virgin Olive Oil?",
      text: "We understand that storing your regular olive oil(s) away from your stovetop is inconvenient, but it is the best method to maintain the product. Our cooking and finishing oils are kept in a locked cabinet in the kitchen, away from heat and moisture. The remaining oils are still in their cases/tanks in our storage. Our storage space maintains a consistent, cool temperature all year. If you live in an area with excessive temperatures and/or humidity all year. try putting the oils in a box under the stairs or a closet in another room.",
    },
    {
      header: "Conclusion",
      text: "For many EVOO will be one of the most expensive foods in their kitchen so it’s a waste to store the product improperly. We want you to enjoy the oil while it’s fresh and full of flavor. Now might be a good time to do an audit of your olive oil storage situation or go through and make sure not of the oils in your pantry are rancid.",
    },
  ],
};
const PressedOlive = {
  name: "What Exactly Is First Cold Pressed Olive Oil?",
  tag: "education",
  description:
    "When you walk into a market, you'll notice the words 'first cold pressed' clearly displayed on dozens of bottles of extra virgin olive oil. It might be in bold or highlighted because the goal is to call attention to the fact that the oil is cold pressed first. You may be thinking, 'Great, but what does it mean?'",
  image: pressed,

  content: [
    {
      header: "What Is the Meaning of First Cold Pressed?",
      text: "First cold pressed indicates that the extra virgin olive oil is made from the first milling of the olives, and the olives and oil are not overheated during the milling process.Extra virgin olive oil is produced according to tight guidelines and criteria. These criteria contain maximums and/or minimums of milling temperature, acidity levels, polyphenol levels, sensory panel results, and other factors. ",
    },
    {
      header: "Why do we still use the term 'first cold pressed'?",
      text: "Because it is helpful for marketing, several brands continue to use the term first cold pressed. After the 1960s, the olive oil sector witnessed a sort of industrial revolution. Mills became entirely mechanized as manufacturers like as Pieralisi made huge strides with the production of decanters and, later, the centrifuge. Almost everyone in the industry now mechanically extracts olive oil from olives. As a result, olives are milled and olive oil is extracted rather than squeezed. Modern technology also allows mill operators to regulate the maximum temperature at which the mill may operate, which is typically 27 degrees Celsius (80.6 degrees Fahrenheit). This signifies that the extra virgin olive oil was milled 'cold.'",
    },
    {
      header: "Is Extra Virgin Olive Oil Cold Pressed First? ",
      text: "To be regarded as 'extra virgin,' extra virgin olive oil must first be cold pressed. As a result, claiming that extra virgin olive oil is cold pressed first is redundant and unnecessary. ",
    },
  ],
};

const FilteredvsUnfiltered = {
  name: "Filtered vs Unfiltered Olive Oil",
  tag: "education",
  description:
    "Regular customers wonder about the distinction between filtered and unfiltered olive oil. ",
  image: filter,

  content: [
    {
      header: "Filtered vs Unfiltered Olive Oil, What Is the Difference?",
      text: "Filtered olive oil is subjected to mechanical filtration. Filtration removes olive oil particles, sediment, residual water, and some flaws. Filtration can reduce the polyphenol content of extra virgin olive oil. Filtered olive oils are sold to consumers after they have passed through the mill, the filter, and the bottle. Mechanical filtration is not used in the production of unfiltered olive oil. It travels from the mill to the bottle before being sold to customers. Polyphenol concentration is still high. ",
    },
    {
      header: "Benefits of Unfiltered Olive Oil",
      text: "Unfiltered olive oil is fantastic since it has the highest polyphenol content and health benefits of any olive oil. In fact, the polyphenol content of freshly milled olives is maximum right after milling. Unfiltered olive oil tastes exactly like freshly squeezed fruit juice. It should be utilized and enjoyed as soon as feasible. ",
    },
    {
      header: "Problems with Unfiltered Olive Oil",
      text: " Unfiltered olive oil, as tasty as it is, is not a very shelf-stable product due to the high olive particle and sediment concentration. This can be important since, after a certain period, the product gets rotten. It degrades considerably faster than filtered oil. As a result, unfiltered olive oil should be obtained immediately after milling and consumed within three months after the milling date or sooner (not to be confused with the bottle date). When olive oil degrades and becomes rancid, it is exceedingly unpleasant to the palate and should not be consumed by humans.",
    },
    {
      header: "Is Unfiltered Olive Oil Considered Extra Virgin?",
      text: " No, because it includes too many faults and degrades too quickly, unfiltered olive oil is not commonly termed extra virgin. While the product is wonderful and enjoyed by chefs, home cooks, and food fans all over the world, it is not classified as extra virgin olive oil. It is crucial to note that extra virgin olive oil must meet various standards in order to be declared extra virgin, therefore while a brand may make olive oil, it may not produce EVOO.",
    },
    {
      header: "Benefits of Filtered Olive Oil",
      text: " Because most potential impurities such as sediment and water have been removed, filtered olive oil has an extraordinarily long shelf life. It also has a high polyphenol content that remains stable over time. When looking for filtered olive oil, look for extra virgin olive oil. All extra virgin olive oil is filtered in order to qualify for the label. For 18 months following the milling date, EVOO retains its outstanding quality. However, it is always advisable to use extra virgin olive oil as soon as possible to avoid it getting rancid.",
    },
    {
      header: "Problems with Filtered Olive Oil",
      text: "To be honest, there aren't many issues with filtered olive oil. The one major disadvantage of filtered oil is that the polyphenol level decreases significantly during the filtration process as sediment and water are removed. However, this is an important step because it results in a very shelf-stable product that we can enjoy all year! ",
    },
  ],
};

const HowtoCook = {
  name: "How to Cook with Olive Oil?",
  tag: "education",
  description:
    "'Can you use extra virgin olive oil to cook with?' Customers frequently ask us this question. Unfortunately, there is a lot of misunderstanding surrounding olive oil. ",
  image: cook,

  content: [
    {
      header: "Can Extra Virgin Olive Oil Be Used to Cook?",
      text: "Yes, you may cook using both regular and extra virgin olive oil. For thousands of years, humans have used olive oil in cooking and food preparation, and it is the foundation of the Mediterranean diet. Many people believe that olive oil, particularly extra virgin olive oil, is the greatest cooking oil option, and thanks to advances in technology, we now have evidence to back that up.",
    },
    {
      header:
        "The Evaluation of Chemical and Physical Changes in Different Commercial Oils during Heating research article states the following:",
      text: "“…EVOO yielded low levels of polar compounds and oxidative by-products, in contrast to the high levels of by-products generated for oils such as canola oil. EVOO’s fatty acid profile and natural antioxidant content allowed the oil to remain stable when heated (unlike oils with high levels of poly-unsaturated fats (PUFAs) which degraded more readily) ….”",
    },
    {
      text: "There's also information on how cooking with extra virgin olive oil can boost the health benefits of the food itself. A study published in the journal Food Chemistry, for example, discovered that phenols and antioxidants from extra virgin olive oil are transferred to vegetables cooked in it, enhancing the nutritional value of the veggies. Another recent study published in the journal Molecules discovered that heating garlic, tomatoes, and onions in olive oil helped release bioactive chemicals and enhance absorption.",
    },
  ],
};

export const educationsBlog = [
  FriendsSustainable,
  PressedOlive,
  FilteredvsUnfiltered,
  HowtoCook,
];
