import { heroVideo, hero } from "../../Home-images";

const HeroVideo = () => {
  return (
    <div className="absolute top-[0] left-0 h-[100%] w-[100%]">
      <video
        className="w-[100%] h-[100%] object-cover z-[0] "
        autoPlay
        loop
        muted
        playsInline
        poster={hero}
        loading={"lazy"}
        webkit-playsinline="true"
        controlsList="nofullscreen"
      >
        <source src={heroVideo} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  );
};

export default HeroVideo;
