import AddToCartButton from "../../AddToCartButton";
import DesktopNavBar from "./DesktopNavBar";

import "../../../Header.css";

const DesktopNavigation = ({ blog }) => {
  return (
    <div className="flex items-center justify-end ml-auto z-[4]">
      <DesktopNavBar blog={blog} />
      <AddToCartButton blog={blog} />
    </div>
  );
};

export default DesktopNavigation;
