//UI
import InnerContainer from "../../../components/container/InnerContainer";
// Styles
import styles from "../../../style";
// data
import { ourProcess } from "../../../constant/About";

import {
  darkGreenOlive,
  lightGreenOlive,
  fruityBottle,
  boldBottle,
} from "../about-images";

// components
import ProcessCard from "./ProcessCard";

const Process = () => {
  return (
    <section className={styles.spacingY}>
      <InnerContainer>
        <h1 className={styles.header}>{ourProcess.header}</h1>
        <div className="flex flex-col md:flex-row relative">
          <ProcessCard
            className={"flex-1 bg-green-dark text-white"}
            header={ourProcess.headerOne}
            discription={ourProcess.discriptionOne}
            oliveImage={lightGreenOlive}
            bottleImage={boldBottle}
          />
          <ProcessCard
            className={"flex-1 bg-green-light text-green-primary"}
            header={ourProcess.headerTwo}
            discription={ourProcess.discriptionTwo}
            oliveImage={darkGreenOlive}
            bottleImage={fruityBottle}
          />
        </div>
      </InnerContainer>
    </section>
  );
};

export default Process;
