// Input Custom Hook
import useInput from "../hooks/user-input";
import FormInputMessage from "./FormInputMessage";
import formStyle from "./formStyle";
import Input from "../components/Input/Input";

const ContactForm = () => {
  const {
    value: nameInput,
    isValid: nameISValid,
    HasError: nameHasError,

    inputChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlueHandler,
    reset: nameReset,
  } = useInput((value) => /^[a-zA-Z]+(\s+[a-zA-Z]+)+$/.test(value));

  const {
    value: phoneNumberInput,
    isValid: phoneNumberIsValid,
    HasError: phoneNumberHasError,

    inputChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHandler,
    reset: phoneNumberReset,
  } = useInput((value) => /^\d{8,}$/.test(value));

  const {
    value: emailInput,
    isValid: emailISValid,
    HasError: emailHasError,

    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlueHandler,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });

  const {
    value: messageInput,
    isValid: messageISValid,
    HasError: messageHasError,

    inputChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlueHandler,
    reset: messageReset,
  } = useInput((value) => value.trim("") !== "");

  let formIsValid = false;
  if (nameISValid && phoneNumberIsValid && emailISValid && messageISValid) {
    formIsValid = true;
  }

  const formSubmitionHandler = async (event) => {
    event.preventDefault();

    if (!formIsValid) return;
    //
    const formData = new FormData(event.target);
    formData.append("access_key", "b5a1954b-f666-4d15-a89f-8329b917b4a7");

    const res = await fetch("", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    if (res.success) {
      alert("Success");
    } else {
      alert("Failed");
    }

    nameReset();
    phoneNumberReset();
    emailReset();
    messageReset();
  };

  function getStyledInput(inputStyle, hasError) {
    return `${inputStyle} ${hasError ? `${formStyle.errorInput}` : ""}`;
  }
  const nameStyle = getStyledInput(formStyle.input, nameHasError);
  const phoneNumberStyle = getStyledInput(formStyle.input, phoneNumberHasError);
  const emailStyle = getStyledInput(formStyle.input, emailHasError);
  const messageStyle = getStyledInput(formStyle.input, messageHasError);

  return (
    <form
      onSubmit={formSubmitionHandler}
      className="flex flex-col w-full md:w-[80%] lg:w-[50%] mx-auto font-zarid text-green-primary text-2xl"
    >
      <input type="hidden" name="from_name" value="Contact Form" />

      <div className="flex flex-col md:flex-row items-center gap-x-4">
        <Input
          boxStyle={"w-full"}
          inputStyle={nameStyle}
          type={"text"}
          name={"firstName"}
          id={"firstName"}
          value={nameInput}
          placeholder={"Full name"}
          onChange={nameChangeHandler}
          onBlur={nameBlueHandler}
          hasError={nameHasError}
          errorMessage={FormInputMessage.text}
        />
        <Input
          boxStyle={"w-full"}
          inputStyle={phoneNumberStyle}
          type={"number"}
          name={"phoneNumber"}
          id={"phoneNumber"}
          value={phoneNumberInput}
          placeholder={"Phone number"}
          onChange={phoneNumberChangeHandler}
          onBlur={phoneNumberBlurHandler}
          hasError={phoneNumberHasError}
          errorMessage={FormInputMessage.number}
        />
      </div>

      <Input
        inputStyle={emailStyle}
        type={"email"}
        name={"email"}
        id={"email"}
        value={emailInput}
        placeholder={"Your Email"}
        onChange={emailChangeHandler}
        onBlur={emailBlueHandler}
        hasError={emailHasError}
        errorMessage={FormInputMessage.email}
      />

      <div>
        <textarea
          className={messageStyle}
          id="message"
          name="message"
          placeholder="Your Message"
          value={messageInput}
          onChange={messageChangeHandler}
          onBlur={messageBlueHandler}
          rows={3}
          wrap="soft"
        />
        <p
          className={`${formStyle.errorText} ${
            messageHasError ? "opacity-1" : "opacity-0"
          }`}
        >
          {FormInputMessage.text}
        </p>
      </div>

      <button
        className={`py-2 px-4 rounded-md mr-3 w-[25%] ml-auto ${
          formIsValid ? `${formStyle.isValidBtn}` : `${formStyle.notValidBtn}`
        }`}
      >
        Submit
      </button>
    </form>
  );
};

export default ContactForm;
