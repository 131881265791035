import { createContext, useState } from "react";

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [formISValid, setFormISValid] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  return (
    <FormContext.Provider
      value={{ userInfo, setUserInfo, formISValid, setFormISValid }}
    >
      {children}
    </FormContext.Provider>
  );
};
