// custom hook
import useGetData from "../../hooks/use-get-data";
// UI
import InnerContainer from "../../components/container/InnerContainer";
// Image
import { meetImage } from "./about-images";
// data
import { meetSama } from "../../constant/About";
// styles
import styles from "../../style";

import getAboutPageContent from "../../services/fetchingData/fetchAboutPageContent";

const MeetSama = () => {
  const data = useGetData(getAboutPageContent);
  return (
    <section className={`${styles.SMspacingY}`}>
      <InnerContainer className="flex flex-col md:flex-row gap-x-4">
        <div className=" w-full md:w-[60%]  flex flex-col mb-6 md:mb-0">
          <h1
            className={
              " text-green-primary font-siriRegular text-4xl sm:text-5xl text-center md:text-start lg:text-center mb-2 "
            }
          >
            {meetSama.header}
          </h1>
          <img
            src={meetImage}
            alt=""
            className="max-h-[550px] md:max-h-[600px] object-cover"
          />
        </div>
        <div className="w-full md:w-[40%] md:mt-auto h-[100%] text-center md:text-start ">
          <p className={`${styles.textMD} font-zarid font-normal md:w-[90%]`}>
            {meetSama.text}
          </p>
        </div>
      </InnerContainer>
    </section>
  );
};

export default MeetSama;
