import ReactDOM from "react-dom";
import { useContext, useEffect } from "react";
// UI
import Button from "../../components/UI/Button/Button";
import CartItem from "./CartItem";

import {
  CartShowContext,
  CartItemsContext,
} from "../../context/Cart-shop-context";

const Cart = () => {
  const { cartOpen, setCartOpen } = useContext(CartShowContext);
  const { items, totalAmount, addItem, removeItem } =
    useContext(CartItemsContext);

  let notItems = items.length === 0;
  const handleClose = () => {
    setCartOpen(false);
  };

  useEffect(() => {
    cartOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [cartOpen]);

  const handleIncreaseQuantity = (item) => {
    const updatedItem = {
      ...item,
      quantity: +1,
    };
    addItem(updatedItem);
  };

  const handleDeacreseQunatity = (item) => {
    if (item.quantity < 2) return;
    const updatedItem = {
      ...item,
      quantity: -1,
    };
    addItem(updatedItem);
  };

  const handleRemove = (item) => {
    removeItem(item);
  };

  return (
    <>
      {cartOpen && (
        <div
          onClick={handleClose}
          className="fixed top-0 left-0 w-[100vw] min-h-[100vh] bg-[#33333366] z-[100] cursor-pointer"
        ></div>
      )}
      {ReactDOM.createPortal(
        <div
          className={`fixed top-0 bottom-0 max-h-[100vh] w-[90vw] sm:w-[65vw]  md:w-[50vw] lg:w-[35vw] xl:[30vw] bg-white flex flex-col transition-all ease-in-out duration-300 z-[200] ${
            cartOpen ? "right-0 opacity-100" : "right-[-100%] opacity-0"
          }`}
        >
          {/* cart Header */}
          <div className="flex items-center justify-between bg-brand py-6 px-3 leading-none text-green-primary capitalize font-siriRegular text-xl">
            <p className="flex items-center ">Cart: [{items.length}]</p>

            <button onClick={() => handleClose()}>[close]</button>
          </div>

          {/* cart body */}
          <div className="flex flex-col gap-y-6 px-2 sm:px-4 overflow-auto mb-6">
            {notItems ? (
              <div className="mt-14">
                <p className="text-2xl text-center font-siriRegular text-green-primary">
                  Time to fill up your cart
                </p>
                <Button
                  className={
                    "font-zarid text-[24px] py-5 px-2 my-4  mx-auto w-[70%]"
                  }
                  to={"shop"}
                  data={"Start shopping here"}
                  onClick={() => handleClose()}
                ></Button>
              </div>
            ) : (
              items.map((item) => (
                <CartItem
                  key={item.id}
                  item={item}
                  handleRemove={handleRemove}
                  handleIncreaseQuantity={handleIncreaseQuantity}
                  handleDeacreseQunatity={handleDeacreseQunatity}
                />
              ))
            )}
          </div>

          {/* subotatl and check out */}
          <div className="mt-auto border-t border-black py-6 px-3">
            <div className="flex items-center justify-between pb-2 font-zarid text-3xl text-green-primary">
              <p className="">Subtotal</p>
              <p className="font-siriRegular text-2xl">{totalAmount}</p>
            </div>
            <div>
              <Button
                onClick={() => handleClose()}
                to={"checkout"}
                data={"Check Out"}
                className={`font-zarid text-3xl py-6 md:py-5 px-2  my-4 w-[100%] ${
                  notItems ? "hidden" : ""
                }`}
              />
            </div>
          </div>
        </div>,

        document.getElementById("cart")
      )}
    </>
  );
};

export default Cart;
