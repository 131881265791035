import { treeImage } from "../blog-image";

const BlogMainHero = (props) => {
  return (
    <section>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-[50%] lg:w-[60%] flex items-center justify-center">
          <img
            className="w-[100%] h-[600px] object-cover"
            src={props.image}
            alt="chef make a recipes with sama olive oil"
          />
        </div>
        <div className="w-full md:w-[50%] lg:w-[40%] py-5 flex flex-col items-center justify-center gap-y-10 bg-brand text-green-primary font-zarid">
          <span className="bg-white px-5 py-2.5 mt-1 flex items-center justify-center font-siriRegular text-xl leading-none  border border-green-primary capitalize">
            {props.tag}
          </span>
          <h2 className="font-siriRegular text-5xl text-center ">
            Olive Oil {props.header}
          </h2>
          <img className="w-[9rem]" src={treeImage} alt="small olive tree" />
          <p className="text-3xl leading-none text-center tracking-tighter">
            {props.text}
          </p>
        </div>
      </div>
    </section>
  );
};

export default BlogMainHero;
