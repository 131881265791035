// Components
import BlogMainHero from "./Blog components/BlogMainHero";
import BlogBody from "./Blog components/BlogBody";
// General Images
import { education } from "./blog-image";
// data
import { educationsBlog } from "../../constant/Education/EducationsBlogs";
const Education = () => {
  return (
    <div>
      <BlogMainHero
        tag={"education"}
        header={"Education"}
        text={"All you need to know about the olive oil."}
        image={education}
      />
      <BlogBody data={educationsBlog} to={"/blog/eduction/educationBlog"} />
    </div>
  );
};

export default Education;
